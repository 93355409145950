import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import facebookIcon from "../../images/logo-facebook.svg"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { deleteSocialAccounts, saveFacebook } from "../../../Redux/Actions/SocialActions";
import { FaSpinner } from "react-icons/fa"
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import SweetAlert from "../../CommonComponent/SweetAlert";

const ConnectFacebook = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });

    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });

    const [groups, setGroups] = useState([]);

    const deleteIntegration = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: sweet.id,
            user_id: auth.user.id
        }
        dispatch(deleteSocialAccounts("delete-social-network", data, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }


    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick = () => {
        setLoader(true);
        const data = {
            user_id: auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        dispatch(saveFacebook(data, setLoader));
        setDetails({ ...details, openPopup: false });
    };

    const responseFacebook = async (response) => {
        try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );
            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
        } catch (err) {
            console.log(err);
        }

        /** Fetch FB Groups */
        try {
            const res = await axios.get(
                " https://graph.facebook.com/me/groups?access_token=" + response.accessToken
            );

            setGroups(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <div className="mt-3" id="facebook">
                <div>
                    {
                        props.data.length > 0 ?
                            <div className="connection-single" style={{ display: "block" }}>
                                {
                                    props.data.map((item, index) => {
                                        return (
                                            <>
                                                <div key={index} className="facebook-connect-account d-flex justify-content-between  align-items-center mt-md-4 mt-lg-0 mt-4 mb-4">
                                                    <div className="connection-left">
                                                        <div className={`connection-logo ${props.data.length > 0 ? '' : 'disable-img'}`}>
                                                            <img src={require('../../../assets/images/icon/facebook.png')} alt="facebook" />
                                                        </div>
                                                        <div>
                                                            <p><strong>Facebook</strong></p>
                                                            <p className="pt-0">{item?.username} - Added on {item.created}</p>
                                                        </div>
                                                    </div>

                                                    <div className="facebook-delete">
                                                        <button type="button" onClick={(e) => deleteIntegration(item.id)} className="site-link"><span>Disconnect</span></button>
                                                    </div>
                                                </div>
                                                {index === props.data.length - 1 ?
                                                    <div className="facebook-connect-account d-flex justify-content-center connection-right">
                                                        <FacebookLogin
                                                            appId="780210132546000" //APP ID NOT CREATED YET
                                                            fields="name,email"
                                                            scope="pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                                                            callback={(e) => responseFacebook(e)}
                                                            render={renderProps => (
                                                                <button onClick={renderProps.onClick} className="site-link"><span>Connect New Account</span></button>
                                                            )}
                                                        />
                                                    </div>
                                                    : ""}
                                            </>
                                        )

                                    })}
                            </div>
                            :
                            <div className="connection-single" style={{ flexDirection: "row" }}>
                                <div className="connection-left">
                                    <div className={`connection-logo ${props.data.length > 0 ? '' : 'disable-img'}`}>
                                        <img src={require('../../../assets/images/icon/facebook.png')} className={props.data.length > 0 ? "" : "disable"} alt="facebook" />
                                    </div>
                                    <p><strong>Facebook</strong></p>
                                </div>
                                <div className="connection-right">
                                    <div className="connection-right">
                                        <FacebookLogin
                                            appId="780210132546000" //APP ID NOT CREATED YET
                                            fields="name,email"
                                            scope="pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                                            callback={(e) => responseFacebook(e)}
                                            render={renderProps => (
                                                <button onClick={renderProps.onClick} className="site-link"> <span>Connect</span> </button>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>


            <Modal className='theme-modal xl' show={details.openPopup} onHide={closeConnectModel} centered >
                <Modal.Body>
                    <div className="modalClose" onClick={closeConnectModel}><AiOutlineClose /></div>
                    <div className='connect-modal'>
                        <h3>Select an account</h3>
                        <hr />
                        <div className="alert alert-info" role="alert">
                            Please note that, you can post into Facebook Pages and Facebook Groups only if you have the administrator rights for that Page or Group. If a Page or Group of yours is not listed which means you might not have the administrator rights for that Page or Group.
                        </div>
                        <div className='row'>
                            <div className='common-input-wrap mt-0'>
                                <select
                                    className="common-input"
                                    value={dropValue}
                                    onChange={handleChange}
                                >
                                    <option value="Select an Account" >Select An Account</option>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map((page) => (
                                            <option
                                                key={page.id}
                                                value={
                                                    page.name +
                                                    "___CR___" +
                                                    page.access_token +
                                                    "___CR___page___CR___" +
                                                    page.id
                                                }
                                            >
                                                {page.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                    <optgroup label="Your Facebook Groups">
                                        {groups.map((group) => (
                                            <option
                                                key={group.id}
                                                value={
                                                    group.name +
                                                    "___CR___" +
                                                    details.accessToken +
                                                    "___CR___group___CR___" +
                                                    group.id
                                                }
                                            >
                                                {group.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3' style={{ display: "flex", justifyContent: "right" }}>


                        <button className="site-link bdr" style={{ cursor: "pointer" }} type='button' onClick={closeConnectModel}><span>Cancel</span></button>


                        <button className="ms-2 site-link" type='button' style={{ cursor: "pointer" }} onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}><span>{loader ? <>{details.buttonText} <FaSpinner className="spin" /> </> : details.buttonText} </span></button>


                    </div>
                </Modal.Body>
            </Modal>



            <SweetAlert
                show={sweet.enable}
                message={"Are you sure? Do you want to delete this facebook account?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                alertLoader={sweet.loader}
                performDelete={onConfirm}

            />
        </>
    )
}

export default ConnectFacebook;