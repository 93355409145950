import React, { useState } from 'react';

function FbTemplates() {

  return (
    <>
      <div className="tabepaneIn">
        <h2 className="tabTitle">FB Templates</h2>
        <div className="account-block alt">
          <div className="tabepaneIn-main">
            <div className="tabepaneIn-cont">
              <h3>Facebook Ads Templates</h3>
              <div className="upgrade-para">
                <p className="text-center " style={{ fontSize: "16px" }}>
                  Download this done-for-you Facebook and Instagram ad
                  creatives and ad copy templates that can easily be
                  customized to be used for any niche
                </p>
              </div>
            </div>

            <div className="tabepaneIn-cont mt-4">
              <h3>Download your Facebook Ads Templates here</h3>
              <a
                rel="noreferrer"
                href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                target="_blank"
              >
                <button class="upgrade-box-content text-center p-3 text-white site-link">
                  <span>  Download the Templates</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default FbTemplates;