import React from 'react'
import { AbsoluteFill, Audio, staticFile } from "remotion";


const AudioLayer = ({ src, volume = 1, loop = true }) => {
    return (
        <AbsoluteFill>
            {src !== "" ?
                <Audio
                    src={src}
                    volume={volume}
                    loop={loop}
                    onError={(event) => {
                        // Handle image loading error here
                        console.log("error")
                    }}
                />
                : ""
            }
        </AbsoluteFill>
    )
}

export default AudioLayer
