import React, { useEffect, useState } from 'react'
import strip from '../../../assets/images/Stripe.png'
import CreateIntegrationModal from './CreateIntegrationModal'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStripeAccounts } from '../../../Redux/Actions/WhiteLabelActions'
import InteRow from './InteRow'

const PaymentIntegration = () => {
    const dispatch = useDispatch()
    const stripe = useSelector(state => state.wl.stripe)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const fetchSocial = () => {
        dispatch(fetchStripeAccounts())
    }

    useEffect(() => {
        fetchSocial()
    }, [])

    return (
        <div className="White_label_wrap">

            <div className="white-titleBar-left">
                <h2>Payment Integration</h2>
            </div>
            <div className="white-block">
                <div className="white_label_integration white_label_integration_small">
                    <div className="white_label_left_integration">
                        <div className="white_label_integration_img">
                            <img src={strip} alt="" />
                        </div>
                        <div className="white_label_integration_text">
                            <h6>Stripe</h6>
                            <p>Connect Stripe</p>
                        </div>
                    </div>

                    <div className="white_label_right_integration">
                        <button className='white-theme-btn' onClick={handleShow}>Connect</button>
                    </div>
                </div>
                {stripe.length > 0 ?
                    stripe.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <InteRow
                                    curElem={curElem}
                                    fetchSocial={fetchSocial}
                                />
                            </React.Fragment>
                        )
                    }) : ""}
            </div>

            <CreateIntegrationModal
                show={show}
                handleClose={handleClose}
                fetchSocial={fetchSocial}
            />
        </div>
    )
}

export default PaymentIntegration