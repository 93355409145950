import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { onEditClient } from '../../../Redux/Actions/AccountManageAction';

const EditMemberModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [details, setDetails] = useState({
        id: "",
        name: "",
        email: "",
        password: ""
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const [emailMsg, setEmailMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setDetails({
            ...details,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(details.email)) {
            setEmailMsg({ ...emailMsg, msg: "", validation: true })
            if (details.password) {
                if (pattern.test(details.password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(onEditClient(details, props.fetchMembers, props.handleClose, setLoader))
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
                }
            } else {
                setLoader(true);
                dispatch(onEditClient(details, props.fetchMembers, props.handleClose, setLoader))
            }
        } else {
            setEmailMsg({ ...emailMsg, msg: "Please enter a valid email", validation: true })
        }
    }


    useEffect(() => {
        setDetails({
            ...details,
            name: props.user.name,
            email: props.user.email,
            id: props.user.id
        })

        return () => {
            setDetails({
                ...details,
                id: "",
                name: "",
                email: "",
                password: ""
            })

            setPasswordMsg({ ...passwordMsg, msg: "", validation: false })
            setEmailMsg({ ...emailMsg, msg: "", validation: false })
        }
    }, [props])

    return (
        <Modal className='theme-modal xl' show={props.show} onHide={props.handleClose} centered scrollable>
            <Modal.Body>
                <div className='info-tab'>
                    <h3>Edit Team Member</h3>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='common-input-wrap'>
                                <label htmlFor="">Account Type</label>
                                <input
                                    className='common-input'
                                    type="text"
                                    value={props.type}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='common-input-wrap'>
                                <label htmlFor="">Account Name</label>
                                <input
                                    className='common-input'
                                    type="text"
                                    name='name'
                                    value={details.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='common-input-wrap'>
                                <label htmlFor="">Email</label>
                                <input
                                    className='common-input'
                                    type="email"
                                    name='email'
                                    value={details.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{emailMsg.validation ? emailMsg.msg : ""}</p>
                        </div>
                        <div className='col-sm-6'>
                            <div className='common-input-wrap'>
                                <label htmlFor="">Password</label>
                                <input
                                    className='common-input'
                                    type="password"
                                    value={details.password}
                                    name='password'
                                    onChange={handleChange}
                                />
                            </div>
                            <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='site-link bdr' onClick={props.handleClose}><span>Cancel</span></button>
                <button className='site-link' onClick={handleSubmit}><span>{loader ? <>Saving <i className="fa fa-spinner fa-spin mx-1" /></> : "Save"}</span></button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditMemberModal