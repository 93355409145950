import React, { useState, useEffect } from 'react';
import { GiPauseButton } from 'react-icons/gi'
import { BsMicFill, BsPlayFill, BsStopFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux';
import useRecorder from '../../../CommonComponent/MyRecording';
import { fetchData, onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import AudioTrack from '../../../CommonComponent/AudioTrack';

let newIntervalId, music = false
const Recording = ({ onSelectAudio, selectedAudio, setSelectedAudio }) => {
    let [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording, setAudioURL] = useRecorder();

    const dispatch = useDispatch()
    const [play, setPlay] = useState(false)
    const [recordingData, setRecordingData] = useState([])
    const [second, setSecond] = useState(0)
    const [percent, setPercent] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false,
        playRecording: false
    })

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        if (type === "stop") {
            stopRecording()
            setT(false)
            clearInterval(newIntervalId);
            let totalTime = timer.h * 3600 + timer.m * 60 + second
            setAudioDuration(totalTime)
            setSecond(0)
            setTimer({
                ...timer,
                h: 0,
                m: 0
            })
            return;
        }

    };
    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])


    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    // let clearPause
    const handlePlay = () => {
        if (audioURL) {
            setLoader({
                ...loader,
                playRecording: true
            })
            const file = URL.createObjectURL(audioURL)
            music = new Audio(file)
            music.play()
                .then(r => {
                    setLoader({
                        ...loader,
                        playRecording: false
                    })
                    setPlay(true)
                }
                )
                .catch(err => console.log(err));
        }
    }

    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])

    const handlePause = () => {
        music.pause()
        setPlay(false)
    }


    const handleUpload = async () => {
        if (audioURL !== '') {
            setLoader({
                ...loader,
                upload: true
            })

            const fileName = new File([audioURL], "audio.mp3");
            const formData = new FormData();
            formData.append('upload_type', "recording")
            formData.append('file', fileName)
            dispatch(onUploadMedia(formData, fetchRecording, loader, setLoader, setPercent))

        }
    }

    const fetchRecording = () => {
        const data = { type: 'recording' }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-user-uploaded-file", data, setRecordingData, loader, setLoader))
    }

    useEffect(() => {
        fetchRecording()
    }, [])

    return (
        <div className="innertab">
            <div className="innertab-scroll alt">
                <div className="voice-record">
                    <div className='row'>
                        <div className="col-12">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className='sound-control'>
                                <div className="voice-record-time text-center">
                                    <span className="mb-2">
                                        {
                                            timer.h < 10 ? '0' + timer.h : timer.h
                                        }
                                        :
                                        {
                                            timer.m < 10 ? '0' + timer.m : timer.m
                                        }
                                        :
                                        {
                                            second < 10 ? '0' + second : second
                                        }
                                    </span>
                                </div>

                                <div className='rec-icon'>
                                    <div className="record-img rounded-circle text-center ">
                                        {status.start ?
                                            !status.pause ?
                                                <GiPauseButton
                                                    fontSize={30}
                                                    className="cursor-pointer"
                                                    onClick={() => handleClick("pause")}
                                                /> : <BsPlayFill
                                                    fontSize={30}
                                                    className="cursor-pointer"
                                                    onClick={() => handleClick("resume")}
                                                /> :
                                            <BsMicFill
                                                fontSize={30}
                                                onClick={() => handleClick("start")}
                                                className="cursor-pointer"

                                            />
                                        }
                                    </div>
                                    <div className="record-img rounded-circle text-center">
                                        {status.start ?
                                            < BsStopFill
                                                fontSize={30}
                                                color="red"
                                                onClick={() => handleClick("stop")}

                                                className="cursor-pointer"
                                            /> : <BsStopFill
                                                fontSize={30}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="voice-block-bottom mt-4">
                                <div className="row">
                                    <div className="col-6">
                                        {play ? <button
                                            className="site-link full btn-sm"
                                            onClick={handlePause}
                                        >
                                            <span>Pause</span>
                                        </button> :
                                            <button
                                                className="site-link bdr full btn-sm"
                                                onClick={handlePlay}
                                                style={audioURL === "" ? { background: "inherit" } : {}}
                                            >
                                                <span> Play</span> {loader.playRecording ? <i className="fa fa-spinner fa-spin" /> : ""}
                                            </button>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <button
                                            className="site-link full btn-sm"
                                            style={audioURL === "" ? { background: "" } : {}}
                                            onClick={handleUpload}
                                        >
                                            {loader.upload ?
                                                <> <span>Uploading</span>  <i className="fa fa-spinner fa-spin mx-1" /></> : <span>Upload</span>
                                            }

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='audio-list mt-3'>
                        <ul className='recording-wrapper'>

                            {recordingData.length > 0 ?
                                recordingData.map((item, index) => {
                                    return (
                                        <AudioTrack
                                            name={`Uploaded VoiceOver ${index + 1}`}
                                            url={item.url}
                                            fetchAudios={fetchRecording}
                                            type="uploaded"
                                            onSelectAudio={onSelectAudio}
                                            selectedAudio={selectedAudio}
                                            setSelectedAudio={setSelectedAudio}
                                            id={item.id}
                                            key={index}
                                        />
                                    )
                                }) : ''}
                            {
                                loader.fetch ?
                                    <div className="col-md-12 mt-2">
                                        <h4 className='text-center'><i className="fa fa-spinner fa-spin " style={{ color: '#23a4ee' }} /></h4>
                                    </div>
                                    : ''
                            }
                        </ul>
                    </div>
                </div>

            </div>
        </div >

    );
}

export default Recording;
