import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsCaretDownFill } from 'react-icons/bs'
import { MdPersonOutline, MdLockOutline, MdPersonAddAlt, MdStarBorder, MdOutlineHelpOutline, MdOutlineLogout, MdOutlineInbox } from 'react-icons/md'
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../Redux/Actions/AuthActions";
import { AiOutlineMergeCells } from "react-icons/ai";
import { CiSettings } from "react-icons/ci";
const Header = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand)
    console.log(rebrand)
    const [scroll, setScroll] = useState(false)
    const [membership, setMembership] = useState([])
    const [isActive, setActive] = useState(false);
    const [mainIs, setMainIs] = useState(false);
    const [accountType, setAccountType] = useState([]);
    const [is_client, setIsClient] = useState(false);
    const [ioMember, setIoMember] = useState(false)
    const [is_team, setIsTeam] = useState(false);
    useEffect(() => {

        if (auth) {
            if (auth.user) {
                setMembership(auth.user.membership.split("__"))

            }
        }
    }, [auth])
    // useEffect(() => {
    //     if (auth.user) {
    //         if (auth.user.account_type?.length === 1 && auth.user.account_type[0] === "IO") {
    //             setIoMember(true)
    //         }
    //     }
    // }, [auth.user])
    useEffect(() => {
        if (auth.user) {
            setIsClient(!auth.user.is_client ? false : auth.user.is_client);
            setIsTeam(!auth.user.is_team ? false : auth.user.is_team);
            setMainIs(auth.user.isWl);
        }
    }, [auth.user]);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const toggleClass = () => {
        setActive(!isActive);
    }

    const handleOut = () => {
        dispatch(logoutUser())

    }



    return (
        <>
            <header className={scroll ? "site-header fixed" : "site-header"}>
                <div className="container">
                    <div className="header-main">
                        <div className="header-left">
                            <NavLink className="logo" to="/home">
                                <img className="logo-light" alt="" src={rebrand.data.
                                    reseller ? rebrand.data.
                                        reseller.logo : require('../../assets/images/logo.png')} />
                                <img className="logo-dark" alt="" src={rebrand.data?.
                                    reseller ? rebrand.data?.reseller.logo : require('../../assets/images/logo-dark.png')} />

                            </NavLink>
                            <div className={isActive ? 'mobClick open' : 'mobClick'} onClick={toggleClass}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>

                        <div>
                            <div className={isActive ? 'site-nav act' : 'site-nav'} onClick={toggleClass}>
                                <ul>
                                    <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                                    <li><NavLink to="/projects">Projects</NavLink></li>
                                    {+auth.user.isClientAccount === 1 ? "" :
                                        <li><NavLink to="/integration">Integration</NavLink></li>
                                    }
                                    <li><NavLink to="/help">Help</NavLink></li>

                                </ul>
                            </div>
                        </div>

                        <div className="header-right">
                            <div className="profile-drop">
                                <Dropdown>
                                    <Dropdown.Toggle variant="tt">
                                        <div className="pro-pic">
                                            <img alt="" src={auth.user.profile} />
                                            <div className="pro-stat"></div>
                                        </div>
                                        <BsCaretDownFill />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul>
                                            <li><NavLink to="/my-account"><span><MdPersonOutline /> </span> My Account</NavLink></li>
                                            {+auth.user.isClientAccount === 1 ? "" :
                                                <>
                                                    {+auth.user.isVirtualAccount === 1 ? "" :
                                                        <>
                                                            <li><NavLink to="/privacy"><span><MdLockOutline /> </span> Privacy</NavLink></li>
                                                            {membership.includes("enterprise") || membership.includes("fastpass") || membership.includes("bundle") ?
                                                                <li><NavLink to="/add-account"><span><MdPersonAddAlt /> </span> Account Management</NavLink></li> : null}
                                                        </>
                                                    }

                                                    {membership.includes("enterprise") || membership.includes("fastpass") || membership.includes("bundle") ?
                                                        <>
                                                            <li> <NavLink to="/reel-merge"><span><AiOutlineMergeCells /> </span> Reelmerge</NavLink></li>
                                                            <li> <NavLink to="/settings"><span><CiSettings /> </span> Settings</NavLink></li>
                                                        </>

                                                        : null}
                                                    {membership.includes("ultimate") || membership.includes("enterprise") || membership.includes("fastpass") || membership.includes("bundle") ?
                                                        <li> <NavLink to="/upgrade"><span><MdStarBorder /> </span> Upgrade</NavLink></li> : null}
                                                </>

                                            }
                                            {/* {mainIs === "0" &&
                                                (is_client === true ? null :
                                                    accountType.includes("bundle") && ( */}
                                            <li> <NavLink className="dropdown-item" to="/white-label">
                                                {/* <i className="fa fa-user" aria-hidden="true"></i> */}
                                                <MdOutlineInbox fontSize={18} />
                                                <span className="pl-2 ms-2" style={{ fontStyle: "normal", fontSize: "15px", fontWeight: "300", color: "#000" }}>
                                                    White Label
                                                </span>
                                            </NavLink></li>
                                            {/* )
                                                )
                                            } */}

                                            <li><NavLink to="/help"><span><MdOutlineHelpOutline /> </span> Help</NavLink></li>
                                            <li><NavLink to="" onClick={handleOut}><span><MdOutlineLogout /> </span> Log Out</NavLink></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        </>
    )
}

export default Header;