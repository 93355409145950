import axios from 'axios';
import React, { useState } from 'react';
import { Search } from 'react-iconly'
import { useSelector } from 'react-redux';

const YoutubeKeySearch = () => {
    const auth = useSelector(state => state.auth)
    const [key, setKey] = useState({
        text: '',
        loader: false
    });
    const [searchData, setSearchData] = useState([])

    const handleSubmit = () => {
        const temp = { keyword_suggestion: key.text }
        setKey({
            ...key,
            loader: true
        })
        axios({
            method: 'POST',
            url: 'https://app.rankreel.io/Keywordsget',
            data: temp,
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.data.status === true) {
                setSearchData(res.data.data)
            }
            setKey({
                ...key,
                loader: false
            })
        }).catch((error) => {
            console.log(error)
            setKey({
                ...key,
                loader: true
            })
        })
    }

    return (
        <div className="tabepaneIn">
            <h2 className="tabTitle pb-4">Youtube Keyword Research</h2>
            <div className="row ">
                <div className="col-md-12 text-center">
                    <div className="project-input-box">
                        <div className="input-group">
                            <div className='row w-100'>
                                <div className='col-xl-12'>
                                    <div className='input-wrap searchInputWarp with-icon w-full d-flex align-items-center '>
                                        <span className='inp-icon'><Search /></span>
                                        <input
                                            className='inp'
                                            type="text"
                                            placeholder='Search'
                                            onChange={(e) => setKey({
                                                ...key,
                                                text: e.target.value
                                            })}
                                        />
                                        <div className="searchBtnWrap">
                                            <button type='submit' className='site-link' onClick={handleSubmit}> <span>Search</span> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {key.loader ?
                <div className='text-center mt-5'>
                    <i className="fa fa-spinner fa-spin ms-1" style={{ fontSize: 25, color: "#ee1085" }} />
                </div>
                :
                searchData.length > 0 ?
                    <div className="row  d-flex justify-content-center  ">
                        <div className="col-md-12 mt-5">
                            <div className="upgrade-box-content upgradeContent text-white">
                                <table className="table table-striped" height="500px">
                                    <thead>
                                        <tr className='tbTitle'>
                                            <th scope="col">Keywords</th>
                                            <th scope="col">Monthly</th>
                                            <th scope="col">Search</th>
                                            <th scope="col">Suggested Bid ($)</th>
                                            <th scope="col">Competition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map((curElem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{curElem.kw}</th>

                                                    <td>{curElem.monthly !== undefined ?
                                                        curElem.monthly.length > 0 ?
                                                            curElem.monthly.map((month) => {
                                                                if (month !== null) {
                                                                    return (<>
                                                                        {month + ' '}
                                                                    </>
                                                                    )
                                                                }
                                                            }) : '' : ''}
                                                    </td>

                                                    <td>{curElem.n}</td>
                                                    <td>{curElem.sb}</td>
                                                    <td>{curElem.con}</td>
                                                </tr>
                                            )
                                        }
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    : ''}
        </div>
    );
}

export default YoutubeKeySearch;
