import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/general-sans.css'
import '../src/assets/css/icomoon.css'
import './App.css';
import './CommonCss.css';
import "../src/WhiteLabel.css"
import './assets/css/light-theme.css'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home/Home';

import { useEffect, useState } from 'react';
import { loadUser } from './Redux/Actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from './Redux/Actions/AlertActions';

import Upgrade from './components/pages/Upgrades/Upgrade';
import AccountManagement from './components/pages/AccountManagement/AccountManagement';
import ForgotPassword from './components/pages/Authentication/ForgotPassword';
import Login from './components/pages/Authentication/Login';
import PrivateRoute from './components/CommonComponent/PrivateRoute';
import Alert from './components/CommonComponent/Alert';
import Privacy from './components/pages/MyAccount/Privacy';
import MyAccount from './components/pages/MyAccount/MyAccount';

import MasterLogin from './components/pages/Authentication/MasterLogin';
import ResetPassword from './components/pages/Authentication/ResetPassword';
import Help from './components/pages/HelpSupport/Help';
import Training from './components/pages/HelpSupport/Training';
import SupportArticle from './components/pages/HelpSupport/SupportArticle';
import { onFetchFont } from './Redux/Actions/CommonActions';
import Projects from './components/pages/Projects/Projects';
import ProjectContainer from './components/pages/Projects/ProjectContainer';
import CreateVideo from './components/pages/Projects/CreateProject/CreateVideo';
import Integration from './components/pages/Integration/Integration';
import Editor from './components/pages/Editor/Editor';
import CreateScript from './components/pages/Projects/CreateProject/Script/CreateScript';
import ChooseTemplate from './components/pages/Projects/CreateProject/ChooseTemplate';
import ChooseCharacter from './components/pages/Projects/CreateProject/ChooseCharacter';
import ReelMerge from './components/pages/ReelMerge/ReelMerge';
import Settings from './components/pages/Settings/outreach/Settings';
import WhiteLabelTabs from './components/White-Label/WhiteLabelTabs';
import { onFetchIsWhitelabel } from './Redux/Actions/WhiteLabelActions';

function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)
  const rebrand = useSelector(state => state.rebrand.data)
  const [loader, setLoader] = useState({
    fetch: true,
  });
  const fetchWhiteLabel = () => {
    setLoader({
      ...loader,
      fetch: true,
    });
    let obj = { domain: window.location.host };
    dispatch(onFetchIsWhitelabel(obj, loader, setLoader));
  };

  useEffect(() => {
    if (rebrand?.reseller?.color?.first && rebrand?.reseller?.color?.second) {
      document.documentElement.style.setProperty(
        "--primary",
        rebrand?.reseller?.color?.first
      );
      document.documentElement.style.setProperty(
        "--secondary",
        rebrand?.reseller?.color?.second
      );
    }
  }, [rebrand]);

  useEffect(() => {
    fetchWhiteLabel();
    // fetchUser()
  }, []);
  const fetchUser = () => {
    dispatch(loadUser())
  }

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])


  const fetchFonts = () => {
    dispatch(onFetchFont(false, true))
  }


  useEffect(() => {
    fetchUser()
    fetchFonts()
  }, [])

  return (
    <div className="App">
      <Alert />
      <div>
        <Router>
          <Routes>

            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/master-login" element={<MasterLogin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route exact path="/dashboard" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route exact path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/add-account" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/help" element={<PrivateRoute><Help /></PrivateRoute>} />
            <Route exact path="/upgrade" element={<PrivateRoute><Upgrade /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><SupportArticle /></PrivateRoute>} />
            {/* <Route exact path="/waiting" element={<PrivateRoute><LoaderPage /></PrivateRoute>} /> */}
            <Route exact path="/integration" element={<PrivateRoute><Integration /></PrivateRoute>} />

            <Route exact path="/reel-merge" element={<PrivateRoute><ReelMerge /></PrivateRoute>} />
            <Route exact path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route exact path="/white-label" element={<PrivateRoute><WhiteLabelTabs /></PrivateRoute>} />

            <Route exact path="/projects" element={<PrivateRoute><ProjectContainer /></PrivateRoute>} >
              <Route index element={<Projects />} />
              <Route path="editor" element={<PrivateRoute><Editor /></PrivateRoute>} />
              <Route path="create-video" element={<CreateVideo />} />
              <Route path="create-script" element={<CreateScript />} />
              <Route path="choose-template" element={<ChooseTemplate />} />
              <Route path="choose-character" element={<ChooseCharacter />} />
            </Route>

            <Route path="*" element={<Login />} />

          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
