import React, { useEffect, useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { onChangeHeadPositions, onChangeHeadShape, onChangeHeadSize, onEnableHeadMovement, onEnableTalkingHead, onFetchCharacterList, onUpdateTalkingHeadMedia } from '../../../../Redux/Actions/VideoActions'
import { useDispatch } from 'react-redux'
import dummmyImg from "../../../../assets/images/dummyImage.jpg"
import Swal from 'sweetalert2'
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions'
import TalkingHeadModal from './TalkingHeadModal'

const TalkingHead = () => {
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => +isSelected === 1)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => +isSelected === 1)
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false
    })
    const [characterList, setCharacterList] = useState([])
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const chooseTalkingHead = () => setShow(true)

    const handleChangeEnable = (e) => {
        dispatch(onEnableTalkingHead(e.target.checked, selectedSlideIndex))
    }
    const handleChangeMovement = (e) => {
        dispatch(onEnableHeadMovement(e.target.checked, selectedSlideIndex))
    }

    const onSuccessUpload = (url, type, thumb) => {
        dispatch(onUpdateTalkingHeadMedia(url, type, selectedSlideIndex))
    }

    const handlePositons = (val) => {
        dispatch(onChangeHeadPositions(val, selectedSlideIndex))
    }

    const handleSize = (val) => {

        // m is 175 X 175
        // s = 125 X 125
        // L = 256 X 256
        if (val === "s") {
            dispatch(onChangeHeadSize(125, selectedSlideIndex))
        } else if (val === "m") {
            dispatch(onChangeHeadSize(175, selectedSlideIndex))
        }
        else {
            dispatch(onChangeHeadSize(256, selectedSlideIndex))
        }
    }

    const handleShape = (val) => {
        dispatch(onChangeHeadShape(val, selectedSlideIndex))
    }

    const onInputChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, onSuccessUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: "#00D0B0"
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: "#00D0B0"
                })
            }
        }
    }

    const fetchCharacterList = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchCharacterList(setCharacterList, loader, setLoader))
    }

    useEffect(() => {
        fetchCharacterList()
    }, [])



    return (
        <div className="font-block sad_talker_wrap ">
            <h2 className='tab-title'>Talking Head</h2>
            <div className="opt-select">
                <span className='text-capitalize'>Enable</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={selectedSlide.faceNarration.enable}
                        onChange={handleChangeEnable}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            <hr />
            {selectedSlide.faceNarration.enable ?
                <>
                    <div className="sad_talker_img mt-3 ">
                        {selectedSlide.faceNarration.media.type === "video" ?
                            <video src={selectedSlide.faceNarration.media.url} muted autoPlay />
                            :
                            <img src={selectedSlide.faceNarration.media.url ? selectedSlide.faceNarration.media.url : dummmyImg} alt="" />
                        }
                    </div>
                    <div className='text-center my-3'>
                        <span style={{ cursor: "pointer", color: "#ee1085", fontWeight: "bold", textDecoration: "underline" }} onClick={chooseTalkingHead}>Choose a talking head character</span>
                    </div>
                    <div className='upload-box'>
                        <div className="upload">
                            {loader.upload ?
                                <div className='percent-loader-box'>
                                    <span className='loader-percent'>{percent}%</span>
                                </div>
                                :
                                <>
                                    <FiUpload />
                                    <p>Upload image here</p>
                                    <input
                                        type="file"
                                        accept='image/*'
                                        onChange={onInputChange}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className="mt-3 me-3">
                        <div class="form-check" style={{ display: "flex", alignItems: "center" }}>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                checked={selectedSlide.faceNarration.headMovement}
                                id="flexCheckDefault"
                                onChange={handleChangeMovement}
                            />
                            <label class="form-check-label" for="flexCheckDefault">
                                Include head movements
                            </label>
                        </div>
                    </div>
                    <div className="mt-3 me-3">
                        <div className="d-flex justify-content-between">
                            <div className=" facelogo_position  ">
                                <h2 className='tab-title'>Face Position</h2>
                                <div className="d-flex justify-content-center align-content-center flex-wrap" style={{ width: "100px" }}>
                                    <div className={`position-single ${selectedSlide.faceNarration.media.pos === "top-left" ? "active" : ""}`} onClick={() => handlePositons("top-left")}></div>
                                    <div className={`position-single ${selectedSlide.faceNarration.media.pos === "top-right" ? "active" : ""}`} onClick={() => handlePositons("top-right")}></div>
                                    <div className={`position-single ${selectedSlide.faceNarration.media.pos === "bottom-left" ? "active" : ""}`} onClick={() => handlePositons("bottom-left")}></div>
                                    <div className={`position-single ${selectedSlide.faceNarration.media.pos === "bottom-right" ? "active" : ""}`} onClick={() => handlePositons("bottom-right")}></div>
                                </div>
                            </div>
                            <div className=" facelogo_shape">
                                <h2 className='tab-title'>Face Shape</h2>
                                <div className="d-flex justify-content-center align-content-center flex-wrap" style={{ width: "50px", margin: "0 auto" }}>
                                    <div className={`position-circle ${selectedSlide.faceNarration.media.shape === "circle" ? "active" : ""}`} onClick={() => handleShape("circle")} style={{ cursor: "pointer", borderRadius: "100%" }}></div>
                                    <div className={`position-circle ${selectedSlide.faceNarration.media.shape === "square" ? "active" : ""}`} onClick={() => handleShape("square")} style={{ cursor: "pointer" }}></div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="mt-3 me-3 pb-4">
                        <h2 className='tab-title'>Face Size</h2>
                        <div className="type-div face_size" style={{ padding: "0 40px", }}>
                            <span className={+selectedSlide.faceNarration.media.height === 125 ? "active" : ""} onClick={() => handleSize("s")}>S</span>
                            <span className={+selectedSlide.faceNarration.media.height === 175 ? "active" : ""} onClick={() => handleSize("m")}>M</span>
                            <span className={+selectedSlide.faceNarration.media.height === 256 ? "active" : ""} onClick={() => handleSize("l")}>L</span>
                        </div>


                    </div>

                </> : ""
            }
            <TalkingHeadModal
                show={show}
                handleClose={handleClose}
                loader={loader}
                onSuccessUpload={onSuccessUpload}
                characterList={characterList}
            />
        </div >
    )
}

export default TalkingHead