import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import Swal from 'sweetalert2'
import { onCreateCampaign, onPlatform } from '../../../../Redux/Actions/ProjectActions'
import { useNavigate } from 'react-router-dom'
import Plateform from './Plateform'

const CreateModal = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)

    const [language, setLanguage] = useState([])
    const [country, setCountry] = useState([])
    const [platformData, setPlatformData] = useState(false)

    const [createData, setCreateData] = useState({
        name: "",
        language: "English",
        country: "United States",
        languageCode: "",
        type: "copy",
        dimension: "16x9",
        style: "engaging"
    })

    const handleChange = (e) => {
        let { name, value } = e.target
        if (name === "language") {
            const lang = language.find(({ name }) => name === value)
            if (+lang.polly_support === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Warning...',
                    text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
                    confirmButtonColor: "#ee1085"
                })
            }
        }

        if (name === "dimension") {
            value = value === "1920x1080" ? "16x9" : "9x16"
        }
        setCreateData({
            ...createData,
            [name]: value
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = { ...createData }

        let count = country.find(({ country_name }) => country_name === createData.country)
        let lang = language.find(({ name }) => name === createData.language)
        data.languageCode = `${lang.code}-${count.code}`
        dispatch(onCreateCampaign(data, navigate, setLoader))

    }

    const fetchLanguage = () => {
        dispatch(fetchData("fetch-google-language", {}, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchCountries = () => {
        dispatch(fetchData("countries", {}, setCountry, false, false, false, "shortDataCt"))
    }
    const fetchPlateform = () => {
        dispatch(onPlatform(setPlatformData))
    }

    useEffect(() => {
        if (!props.show) {
            setCreateData({
                ...createData,
                name: "",
                language: "English",
                country: "United States",
                languageCode: "",
                type: "copy",
                dimension: "16x9",
                platformId: "",
                style: "engaging",
            })
            setLoader(false)
        }
    }, [props.show])



    useEffect(() => {
        fetchCountries()
        fetchLanguage()
        fetchPlateform()
    }, [])

    return (
        <Modal className="create-modal theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>
                <form onSubmit={handleSubmit}>

                    <div className="connect-modal">
                        <h3>Create New Project</h3>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Project Name</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        placeholder="Enter Name Here"
                                        name='name'
                                        onChange={handleChange}
                                        value={createData.name}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Country</label>
                                    <select
                                        className='common-input'
                                        value={createData.country}
                                        onChange={handleChange}
                                        name='country'
                                        required
                                    >
                                        <option value={""}>Select Country</option>
                                        {country.length > 0 ?
                                            country.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                                )
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Language</label>
                                    <select
                                        className='common-input'
                                        value={createData.language}
                                        onChange={handleChange}
                                        name='language'
                                        required
                                    >
                                        <option value={""}>Select Language</option>
                                        {language.length > 0 ?
                                            language.filter((lang) => {
                                                return +lang.id !== 40
                                            }).map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.name}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Plateform</label>
                                    <select
                                        name="dimension"
                                        className="common-input"
                                        onChange={handleChange}
                                        required
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {/* <option value="">Select Platform</option> */}
                                        <option value={"1920x1080"}>{"Standard YouTube videos"}</option>
                                        {platformData ?
                                            <>
                                                <Plateform
                                                    data={platformData.Facebook}
                                                />
                                                <Plateform
                                                    data={platformData.Instagram}
                                                />
                                                <Plateform
                                                    data={platformData.LinkedIn}
                                                />
                                                <Plateform
                                                    data={platformData.Pinterest}
                                                />
                                                <Plateform
                                                    data={platformData.Snapchat}
                                                />
                                                <Plateform
                                                    data={platformData.TikTok}
                                                />
                                                <Plateform
                                                    data={platformData.Twitter}
                                                />
                                                <Plateform
                                                    data={platformData.YouTube}
                                                />
                                            </>

                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Style</label>
                                    <select
                                        className="common-input"
                                        name="style"
                                        onChange={handleChange}
                                        value={createData.style}
                                        required
                                    >
                                        <option value={""}>Select Style</option>
                                        <option value={"VSL"}>VSL</option>
                                        <option value={"engaging"}>Engaging</option>
                                        <option value={"promo"}>Promo</option>
                                        <option value={"informative"}>Informative</option>
                                        <option value={"fun"}>Fun</option>
                                        <option value={"news"}>News</option>
                                        <option value={"controversial"}>Controversial</option>
                                        <option value={"Product review"}>Product review</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3' style={{ display: "flex", justifyContent: "right" }}>
                        <button type='button' className='site-link bdr' onClick={props.handleClose}><span>Cancel</span></button>
                        <button className='ms-2 site-link' type='submit'><span className='d-flex align-items-center'>{loader ? <>Creating <i className="fa fa-spinner fa-spin ms-1" /></> : "Create"}</span></button>
                    </div>
                </form>
            </Modal.Body>

        </Modal >
    )
}

export default CreateModal