import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { FiUpload } from 'react-icons/fi';
import { removeLogoWatermark, updateLogoWatermark, updateStatus } from '../../../../Redux/Actions/VideoActions';
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import dummyImage from "../../../../assets/images/dummyImage.jpg"

const LogoWatermark = (props) => {
    const dispatch = useDispatch()
    const position = ["top-left", "top-center", "top-right", "center-left", "center", "center-right", "bottom-left", "bottom-center", "bottom-right"]
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false
    })

    const selectImage = (url, lwType) => {
        let uploadType = 'ADD_LOGO';
        if (lwType === "watermark") {
            uploadType = 'ADD_WATERMARK';
        }
        dispatch(updateLogoWatermark(uploadType, url));
    }

    const handleLogoWaterMark = (e) => {
        dispatch(updateStatus(e.target.checked, props.type))
        if (e.target.checked === false) {
            if (props.type === "logo") {
                dispatch(removeLogoWatermark('REMOVE_LOGO_LAYER'))
            } else {
                dispatch(removeLogoWatermark('REMOVE_WATERMARK_LAYER'))
            }
        }
    }
    const logoWaterPos = (position) => {
        let fileType = 'UPDATE_LOGO_POSITION';
        if (props.type === 'watermark') {
            fileType = 'UPDATE_WATERMARK_POSITION';
        }
        dispatch(updateLogoWatermark(fileType, position));
    }

    const onLoadFile = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
                if (file.size < 5000000) {
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('upload_type', props.type);
                    setLoader(true)
                    dispatch(onUploadMedia(formData, selectImage, loader, setLoader, setPercent, props.type))

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: "#ee1085"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: "#ee1085"
                })
            }
        }
    }

    return (
        <>
            <div className="opt-select mb-3">
                <h6 className='text-capitalize'>{props.type}</h6>
                <label className="switch">
                    <input
                        type="checkbox"
                        name={props.type}
                        id={props.type}
                        checked={props.data.enable}
                        onChange={(e) => handleLogoWaterMark(e)}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            {props.data.enable ?
                <>
                    <div className='upload-box mt-3 px-4'>
                        {loader.upload ?
                            <div className='percent-loader-box'>
                                <span className='loader-percent'>{percent}%</span>
                            </div> :

                            <div className="d-flex justify-content-between align-items-center" style={{ height: "100%", width: "100%" }}>
                                <div>
                                    <FiUpload />
                                    <p className='text-capitalize'>Upload {props.type}</p>
                                    <input
                                        type="file"
                                        accept='image/*'
                                        onChange={(e) => onLoadFile(e)}
                                    />
                                </div>
                                <div className="up_img">
                                    <img style={{ objectFit: 'contain', height: 82 }} src={props.data.src ? props.data.src : dummyImage} alt="" />
                                </div>
                            </div>
                        }
                    </div>


                    <div className="opt-select pt-3">
                        <h6 className='text-capitalize'>{props.type} Position</h6>
                    </div>
                    <div className="position mb-3">
                        <ul>
                            {position.map((curElem, index) => {
                                return (
                                    <li key={index}>
                                        <div
                                            className={`position-single ${props.data.pos === curElem ? "active" : ""}`}
                                            onClick={() => logoWaterPos(curElem, 'logo')}
                                            style={{ cursor: "pointer" }}
                                        >
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </>
                : ""}
        </>
    );
}

export default LogoWatermark;
