import {
  FETCH_PAYMENT_METHODS,
  FETCH_WHITE_LABEL_DETAILS,
  FETCH_WHITELABEL_TEMPLATE,
  SET_GENERAL_DATA,
  SET_SMTP,
  SET_PAYMENT,
  SET_PRICE,
  SET_TEMPLATE_ID,
  FETCH_WHITELABEL_MEMBERSHIP,
  CLEAR_WHITELABEL_DATA,
} from "../Actions/Types";

const initialState = {
  loading: true,
  whiteLabelData: [],
  paymentMethods: [],
  memberShip: [],
  general: {},
  price: [],
  payment: [],
  smtp: {},
  template_id: "",
  template: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_WHITE_LABEL_DETAILS:
      return {
        ...state,
        loading: false,
        whiteLabelData: payload,
      };
    case FETCH_PAYMENT_METHODS:
      return {
        ...state,
        loading: false,
        paymentMethods: payload,
      };

    case FETCH_WHITELABEL_MEMBERSHIP:
      return {
        ...state,
        loading: false,
        memberShip: payload,
      };

    case FETCH_WHITELABEL_TEMPLATE:
      return {
        ...state,
        loading: false,
        template: payload,
      };
    case SET_GENERAL_DATA:
      return {
        ...state,
        loading: false,
        general: { ...payload },
      };

    case SET_SMTP:
      return {
        ...state,
        loading: false,
        smtp: { ...payload },
      };

    case SET_PRICE:
      return {
        ...state,
        loading: false,
        price: [...payload],
      };

    case SET_PAYMENT:
      return {
        ...state,
        loading: false,
        payment: [...payload],
      };

    case SET_TEMPLATE_ID:
      return {
        ...state,
        loading: false,
        template_id: payload,
      };

    case CLEAR_WHITELABEL_DATA:
      return initialState;
    default:
      return state;
  }
}
