import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";



export const fetchRebrandData = () => (dispatch, getState) => {
    commonAxios("fetch-wl-rebranding", {}, dispatch, getState().auth.token)
        .then(res => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                dispatch({ type: 'ADD_REBRAND', payload: res.data });
            }
        }).catch(error => {
            console.log(error)
        })
}


export const addRebrandData = (data, setLoader, setFileData, setName) => (dispatch, getState) => {
    commonAxios("wl-rebranding", data, dispatch, getState().auth.token)
        .then(res => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                dispatch(fetchRebrandData());
                setFileData("")
                setName("")
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            console.log(error)
            setLoader(false);
        })
}

export const onDeleteRebrand = (deleteData, setSweet, setRebrand) => (dispatch, getState) => {
    commonAxios("delete-wl", deleteData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(removeRebrandData())
                dispatch(setAlert(res.msg, "success"))
                setRebrand({
                    name: "",
                    img: "",
                    id: false,
                    isRebrand: false,
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Remove",
                loader: false

            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Remove",
                loader: false

            })
            console.log(err)
        })
}

export const removeRebrandData = () => (dispatch) => {
    dispatch({ type: 'REMOVE_ADD_REBRAND' });
}