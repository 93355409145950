import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsPauseFill, BsPlayFill } from 'react-icons/bs';
import SweetAlert from './SweetAlert';
import { onDeleteMedia } from '../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onPlayEditorMedia } from '../../Redux/Actions/EditorActions';

let clearPause, audio = false
const AudioTrack = (props) => {
    const dispatch = useDispatch()
    const editor = useSelector(state => state.editor)
    const [audioDuration, setAudioDuration] = useState(0)
    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (e) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            enable: true,
            id: props.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        const formData = new FormData();
        formData.append('id', sweet.id);
        dispatch(onDeleteMedia(formData, props.fetchAudios, setSweet))
    }


    const playAudio = (e) => {
        e.stopPropagation()
        if (audioDuration > 0) {
            if (audio !== false) {
                audio.pause();
                setPlay(false);
            }
            setLoader(true);
            audio = new Audio(props.url);
            audio.play()
                .then(r => setLoader(false)).catch(err => console.log(err));
            dispatch(onPlayEditorMedia(props.url))
            setPlay(true)
            clearPause = setTimeout(() => {
                pauseAudio()
            }, audioDuration * 1000)
        }
    }

    const pauseAudio = (e) => {
        if (e) {
            e.stopPropagation()
        }
        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }

    const handleSelect = () => {
        props.onSelectAudio(props.url, audioDuration)
        props.setSelectedAudio(props.url)
    }

    useEffect(() => {
        if (props.url) {
            let au = document.createElement('audio');
            au.src = props.url;

            au.addEventListener('loadedmetadata', function () {
                if (au.duration !== Infinity && au.duration !== 0) {
                    setAudioDuration(Number((au.duration).toFixed(1)));
                    au = null
                }
                else {
                    var getDuration = function (url, next) {
                        var _player = new Audio(url);
                        _player.load();
                        _player.currentTime = 24 * 60 * 60; // fake big time
                        _player.volume = 0;
                        _player.muted = false;
                        _player.play();

                        _player.addEventListener("durationchange", function (e) {
                            if (this.duration != Infinity) {
                                var duration = this.duration
                                _player.remove();
                                next(duration);
                            };
                        }, false);

                        // Wait for a user gesture (e.g., a click) before loading and playing the audio.
                        document.addEventListener("click", function () {
                            _player.load();
                            _player.currentTime = 24 * 60 * 60; // fake big time
                            _player.volume = 0;
                            _player.muted = false;
                            _player.play();
                        }, { once: true }); // Remove the event listener after it's triggered once.
                    };
                    if (au.src) {
                        getDuration(au.src, function (duration) {
                            setAudioDuration(Number((duration).toFixed(1)));
                            au = null
                        });
                    }
                }
            }, false);
        }
    }, [props.url])

    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])


    return (
        <>
            <li className={props.selectedAudio === props.url ? `active-music-file audio-single` : "audio-single"} onClick={handleSelect} style={{ cursor: "pointer" }}>
                <span className='audio-list-left'>
                    {play && editor.audio.currentUrl === props.url ?
                        loader ?
                            <i className='fa fa-spinner fa-spin' style={{ fontSize: 15 }} />
                            :
                            <BsPauseFill
                                onClick={(e) => pauseAudio(e)}
                            />
                        :
                        <BsPlayFill
                            onClick={(e) => playAudio(e)}
                        />
                    }
                </span>

                <span className='audio-txt'>{props.name}</span>
                <span className='audio-dur'>{audioDuration}s</span>
                {props.type === "uploaded" ?
                    <span className='ms-2' style={{ cursor: "pointer" }} onClick={(e) => onDelete(e)}><AiOutlineDelete size={20} /></span> : ""}


            </li>
            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this audio?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                alertLoader={sweet.loader}
                performDelete={performDelete}
            />
        </>
    );
}

export default AudioTrack;
