import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Header from "../../Header/Header";
import Tab from 'react-bootstrap/Tab';
import Audio from "./BgAudio/Audio";
import Setting from "./Settings/Setting";
import Credit from "./Credit/Credit";
import Background from "./BackgroundMedia/Background";
import VoiceOver from "./Voiceover/VoiceOver";
import Transition from "./Transition/Transition";
import Animation from "./Animation/Animation";
import EditTemplate from "./Templates/EditTemplate";
import Script from "./Script/Script";
import Text from "./Text/Text";
import Media from "./LayerMedia/Media";
import EditorNav from "./EditorNav";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import Preview from "./Preview/Preview";
import Header from "../../header/Header";
import TitleBar from "../../title-bar/TitleBar";
import { onFetchVideo, onRenderVideo, onUnmountEditor } from "../../../Redux/Actions/VideoActions";

import VideoTiles from "./VideoTile/VideoTiles";
import TalkingHead from "./TalkingHead/TalkingHead";
import Remotion from "./Remotion/Remotion";
import Characters from "./Characters/Characters";

const Editor = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cid } = queryString.parse(location.search)

    const editor = useSelector(state => state.editor)
    const campaign = useSelector(state => state.video.campaign)

    const [pageLoader, setPageLoader] = useState(true)
    const [preLoader, setPreLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [deactiveButton, setDeactiveButton] = useState(false)
    const [second, setSecond] = useState(0)
    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    const [time, setTime] = useState({
        h: 0,
        m: 0,
        s: 0
    })
    const handleClosePreviewModal = () => setShowPreview(false)

    const fetchVideos = () => {
        let data = {
            cId: cid
        }
        dispatch(onFetchVideo(data, setPageLoader, navigate))
    }

    const handleRenderVideo = () => {
        let data = {
            id: cid
        }
        setLoader(true)
        dispatch(onRenderVideo(data, navigate, setLoader));
    };

    useEffect(() => {
        if (campaign) {
            setPageLoader(false)
        }
    }, [campaign])

    useEffect(() => {
        if (cid) {
            fetchVideos()
        }
    }, [cid])

    useEffect(() => {
        return () => {
            dispatch(onUnmountEditor())
        }
    }, [])

    return (
        !pageLoader ?
            <>
                <TitleBar title="Editor" />
                <Header />

                <div className="site-wrap pb-0">
                    <div className="site-container for-editor">
                        <div className="editor-wrap">
                            <div className="editor-left">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <div className="editor-left-nav">
                                        <EditorNav />
                                    </div>
                                    <div className="editor-left-cont my-editor-left-cont">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first" >
                                                <Script />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" >
                                                <Text />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="background">
                                                <Background />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third" >
                                                <Media />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="characters" >
                                                <Characters />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <Audio />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="voiceover">
                                                <VoiceOver
                                                    setDeactiveButton={setDeactiveButton}
                                                />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="sadTalker">
                                                <TalkingHead />

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="transition">
                                                <Transition />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="animation">
                                                <Animation />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="templates">
                                                <EditTemplate
                                                    setPreLoader={setPreLoader}
                                                    setPageLoader={setPageLoader}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fifth">
                                                <Setting />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="sixth">
                                                <Credit />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </div>
                            <div className="editor-right mt-3 mt-xl-0">
                                <div className='text-end mt-lg-3 mt-sm-3 '>
                                    {/* editor-action remove class for fix ui */}
                                    <button
                                        className='site-link bdr me-1'
                                        onClick={() => setShowPreview(true)}
                                        style={{ background: editor.voiceOver.isGenerating ? "gray" : "" }}
                                        disabled={editor.voiceOver.isGenerating}
                                    >
                                        <span>Preview</span>
                                    </button>
                                    <button
                                        className='site-link '
                                        onClick={handleRenderVideo}
                                        style={{ background: editor.voiceOver.isGenerating ? "gray" : "" }}
                                        disabled={editor.voiceOver.isGenerating}
                                    >
                                        <span>{loader ? <>Rendering <i className="fa fa-spinner fa-spin ms-1" /></> : "Render"}</span>
                                    </button>
                                </div>
                                <Preview
                                    preLoader={preLoader}
                                />
                            </div>
                        </div>

                        <div className="editor-controller">
                            <div className='video-slide'>
                                <VideoTiles />
                            </div>
                        </div>

                    </div>
                </div>
                <Remotion
                    show={showPreview}
                    handleClose={handleClosePreviewModal}
                />

            </> :
            <div className="loader-sec">
                <div className="loader" />
            </div>
    )
}

export default Editor;