import React from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import HomeBanner from './HomeBanner';
import Webinar from './Webinar';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { appName } from '../../../Global/Global';
import VideoProjectContainer from '../Projects/VideoProjectContainer';
import { Link } from 'react-router-dom';

const Home = () => {
    const rebrand = useSelector(state => state.rebrand)


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <TitleBar title="Dashboard" />
            <div className='front-page'>
                <Header />
            </div>

            <HomeBanner />
            <div className="videoProject-top mt-5">
                <h2>Recent <span>Projects</span></h2>
                <Link to="/projects" className="proView">View all <span><i class="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
            </div>
            <VideoProjectContainer
                dimension={""}
                q={""}
                isDash={true}
                type={1}
            />

            <section className='image-block pt-0'>
                <div className='container sm'>
                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-1.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Hands Free</h6>
                                    <h2>Take a Year Worth of Content:</h2>
                                    <p>With {rebrand.data ? rebrand.data.name : appName}'s powerful automation, generate a year's worth of content in a fraction of the time, ensuring a consistent online presence and audience engagement.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-2.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Clients</h6>
                                    <h2>Auto Find Clients for Your Services: </h2>
                                    <p>Let {rebrand.data ? rebrand.data.name : appName}'s smart algorithms identify potential clients and opportunities for your services, helping you grow your business and reach new heights.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-3.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Clients</h6>
                                    <h2>Take on 100s of Clients Effortlessly: </h2>
                                    <p>Scale your business effortlessly with {rebrand.data ? rebrand.data.name : appName}'s efficiency, allowing you to manage and serve hundreds of clients without breaking a sweat, all while delivering top-notch animated videos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Webinar />
            <Footer />
        </>
    )

}

export default Home;