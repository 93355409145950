import React, { useState } from 'react';

function DfyCourse() {

  return (
    <>
      <div className="tabepaneIn">
        <h2 className="tabTitle">DFY Courses</h2>
        <div className="account-block alt">
          <div className="tabepaneIn-main">
            <div className="upgrade-content">
              {/* <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="upgrade-box-content  text-white  ">
                    <div className="upgrade-heading text-center">
                      <h6 className="m-0">DFY Courses</h6>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className=" col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 1 </span></button>
                    </a>
                  </div>
                </div>
                <div className=" col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 11 </span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 2</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 12</span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 3</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 13</span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 4</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 14</span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 5</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 15</span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 6</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 16</span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                      target="_blank"
                    >
                      {" "}
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 7</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip"
                      target="_blank"
                    >
                      {" "}
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 17</span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                      target="_blank"
                    >
                      {" "}
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 8</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 18</span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 9</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 19</span></button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 10</span></button>
                    </a>
                  </div>
                </div>
                <div className="col-2lg col-12">
                  <div className="upgrade-box-content text-center p-3 text-white">
                    <a
                      href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip"
                      target="_blank"
                    >
                      <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Download DFY Course 20</span></button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default DfyCourse;