import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi'
import { GiCheckMark } from 'react-icons/gi';
import { IoClose } from "react-icons/io5";
import { updateTopWearObj } from '../../../../Redux/Actions/VideoActions';
import { useDispatch } from 'react-redux';


const CharacterModal = (props) => {
    const dispatch = useDispatch()
    const charRef = useRef(null)
    const [q, setQ] = useState("")
    const [useLoader, setUseLoader] = useState(false)
    const [activeAnim, setActiveAnim] = useState({
        index: -1
    })
    const [selected, setSelected] = useState({
        index: -1,
        url: "",
        category: "",
        charString: "",
        characterId: -1,
    })

    const handleSearch = (e) => {
        e.preventDefault()
        let finalSearch = props.data.filter((curElem) => {
            return curElem.keywords.toLowerCase().includes(q.toLowerCase())
        })
        props.setCharShow(finalSearch)
    }

    const handleActiveAnim = (index) => {
        setActiveAnim({
            ...activeAnim,
            index: index
        })

    }

    const handleDeactiveAnim = () => {
        setActiveAnim({
            ...activeAnim,
            index: -1
        })
    }

    const handleSelect = (curElem, index) => {
        setSelected({
            ...selected,
            index: index,
            url: curElem.url,
            category: curElem.category,
            characterId: curElem.id
        })
    }

    const setColor = (xmlDoc, className, color) => {
        const elements = xmlDoc.children[0].getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].setAttribute("fill", color)
        }
        dispatch(updateTopWearObj(elements.length, className, props.selecteSlideIndex, props.selectedLayerIndex))

    }

    const handleUseData = () => {
        const fetchData = async () => {
            setUseLoader(true)
            await axios.get(selected.url + "?cacheblock=true",).then(data => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data.data, 'text/xml');
                setColor(xmlDoc, "character_hairColor", props.selectedLayer.charColors.hairColor.color)
                setColor(xmlDoc, "character_skinColor", props.selectedLayer.charColors.skinColor.color)
                setColor(xmlDoc, "character_topwearColor", props.selectedLayer.charColors.topwearColor.color)
                setColor(xmlDoc, "character_topwear1Color", props.selectedLayer.charColors.topwear1Color.color)
                setColor(xmlDoc, "character_topwear2Color", props.selectedLayer.charColors.topwear2Color.color)
                setColor(xmlDoc, "character_bottomwearColor", props.selectedLayer.charColors.bottomwearColor.color)
                setColor(xmlDoc, "character_ShoesColor", props.selectedLayer.charColors.shoesColor.color)
                setColor(xmlDoc, "character_accessoriesColor", props.selectedLayer.charColors.accessoriesColor.color)
                xmlDoc.children[0].style.height = "100%"
                xmlDoc.children[0].style.width = "100%"
                const serializer = new XMLSerializer();
                let xmlDataParsed = serializer.serializeToString(xmlDoc)
                let dataObj = {
                    url: selected.url,
                    category: selected.category,
                    charString: xmlDataParsed,
                    characterId: selected.characterId
                }
                setSelected({
                    ...selected,
                    index: -1,
                    url: "",
                    category: "",
                    characterId: -1
                })
                props.handleUse(dataObj, setUseLoader)
            }).catch(error => {
                console.error('Error fetching XML:', error);
            });
        }
        fetchData()
    }

    useEffect(() => {
        if (props.type === "action" && props.selectedLayer.type === "character") {
            props.setCharShow(props.data.filter((curElem) => {
                return curElem.category === props.selectedLayer.category
            }))
        } else if (props.type === "char" && props.selectedLayer.type === "character") {
            if (props.data.length > 0) {
                // props.setCharShow(props.data)
                props.setCharShow(props.data.slice(0, 12))
            }
        }
    }, [props.type])

    return (
        <Modal show={props.show} onHide={props.handleClose} className='character_modal'>
            <Modal.Header >
                <Modal.Title ><h3>Characters</h3></Modal.Title>
                <button type="button" class="btn-close" aria-label="Close" onClick={props.handleClose} style={{ background: "none", }}><IoClose style={{ color: "#fff", fontSize: "34px" }} /></button>
            </Modal.Header>
            <Modal.Body>
                {props.type === "char" ?
                    <Form onSubmit={handleSearch}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="10">
                                <Form.Control
                                    type="text"
                                    value={q}
                                    onChange={(e) => setQ(e.target.value)}
                                    required
                                    className='cont-inp'
                                />
                            </Form.Group>
                            <Col md="2">
                                <button type="submit" class="site-link w-100" > <span style={{ fontSize: "12px", width: "100%" }}>Search</span></button>
                            </Col>
                        </Row>
                    </Form> : null}
                <div className="All_character">
                    <Row className="mb-3">
                        {props.charShow.length > 0 ?
                            props.charShow.map((curElem, index) => {
                                return (
                                    <Col md="3" key={index} className='text-center'>
                                        <div
                                            className="character_img"
                                            style={{ cursor: "pointer", borderWidth: selected.index === index ? 2 : 1 }}
                                            onMouseEnter={() => handleActiveAnim(index)}
                                            onMouseLeave={handleDeactiveAnim}
                                            onClick={() => handleSelect(curElem, index)}
                                            title={curElem.keywords}
                                        >
                                            {index === activeAnim.index ?
                                                <span style={{ position: "relative", height: "100%", width: "100%", display: "block" }}>
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                    />
                                                    <object
                                                        data={curElem.url}
                                                        ref={charRef}
                                                        type='text/html'
                                                        style={{ pointerEvents: "none", width: "100%", height: "100%", }}
                                                    />
                                                </span>

                                                :
                                                <img src={curElem.url} alt="" />
                                            }
                                            {
                                                selected.index === index ?
                                                    <div className='check_select'>
                                                        <GiCheckMark style={{ color: "#ee1085", fontSize: "35px" }} />
                                                    </div> : null
                                            }

                                        </div>
                                    </Col>
                                )
                            })
                            :
                            <Col md="12" className='text-center'>
                                {props.loader.fetch ?
                                    <FaSpinner className='spin' color="#ee1085" size={25} />
                                    : ""}
                            </Col>
                        }
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>

                <button
                    className='site-link'
                    onClick={handleUseData}
                    disabled={selected.index === -1}
                >
                    <span>{useLoader ? <>Using <FaSpinner className='spin' size={18} /> </> : "Use"}</span>
                </button>
            </Modal.Footer>

        </Modal>
    )
}

export default CharacterModal