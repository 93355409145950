import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import { onUpdateCharColor, onUpdateCharXml } from '../../../../Redux/Actions/VideoActions'
import { useDispatch } from 'react-redux'

const ColorPalette = (props) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const handleChooseColor = async (e) => {
        let body = document.getElementById("myIframe").contentWindow.document.body
        if (body) {
            let skin = body.getElementsByClassName(props.className)
            for (let i = 0; i < skin.length; i++) {
                await skin[i].setAttribute("fill", e.hex)
            }
            dispatch(onUpdateCharColor(props.className, e.hex, props.selecteSlideIndex, props.selectedLayerIndex))
        }

    }

    const handleClosePicker = () => {
        setShow(false)
        let newSkin = document.getElementById("myIframe").contentWindow.document
        if (newSkin.body) {
            const serializer = new XMLSerializer();
            let xmlDataParsed = serializer.serializeToString(newSkin)
            dispatch(onUpdateCharXml(xmlDataParsed, props.selecteSlideIndex, props.selectedLayerIndex))
        }
    }

    return (
        <div className="d-flex justify-content-between align-items-center" style={{ width: "100%", height: "100%", marginTop: "20px", position: "relative" }}>
            <span>{props.name}</span>
            <span className='color_box' style={{ background: props.color, border: "1px solid #000", borderRadius: 5, cursor: "pointer" }} onClick={() => setShow(true)}></span>
            {show ?
                <>
                    <div style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }} onClick={handleClosePicker} />
                    <SketchPicker
                        color={props.color}
                        onChange={(e) => handleChooseColor(e)}
                        className='right_picker'
                    />
                </>
                : ""
            }
        </div>
    )
}

export default ColorPalette