import React from 'react'
import { AbsoluteFill, Sequence, Img } from 'remotion';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';


const ShapeLayer = ({ layer, multiplyNum }) => {

    const increaseSize = (size) => {
        if (size) {
            let matches = size.match(/(\d+)/);
            let actualSize = parseInt(matches[0]) * multiplyNum;
            return `${actualSize}px`
        }
    }

    let mystyle = {
        position: "absolute",
        display: `inline-block`,
        left: `${+layer.position.left}%`,
        top: `${+layer.position.top}%`,
        width: `${+layer.size.width}%`,
        height: `${+layer.size.height}%`,
        backdropFilter: `${layer.style.backdropFilter}`,
        filter: `${layer.style.filter}`,
        borderRadius: layer.style.borderRadius ? `${layer.style.borderRadius}%` : ""
    }
    let shapeStyle = {
        position: "absolute",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        display: "inline-block",
        color: `${layer.style.color}`,
        background: `${layer.style.background}`,
        opacity: `${layer.style.opacity}`,
        boxShadow: `${layer.style.boxShadow}`,
        border: `${layer.style.border}`,
        borderWidth: `${increaseSize(layer.style.border)}`,
        objectFit: `${layer.style.objectFit}`,
        borderTopRightRadius: `${layer.style.borderTopRightRadius === undefined ? '' : increaseSize(layer.style.borderTopRightRadius)}`,
        borderBottomRightRadius: `${layer.style.borderBottomRightRadius === undefined ? '' : increaseSize(layer.style.borderBottomRightRadius)}`,
        borderTopLeftRadius: `${layer.style.borderTopLeftRadius === undefined ? '' : increaseSize(layer.style.borderTopLeftRadius)}`,
        borderBottomLeftRadius: `${layer.style.borderBottomLeftRadius === undefined ? '' : increaseSize(layer.style.borderBottomLeftRadius)}`,
        borderRadius: layer.style.borderRadius ? `${layer.style.borderRadius}%` : "",
        clipPath: `${layer.style.clipPath}`,
        transform: `${layer.style.transform}`
    }


    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill>
                <Animation style={mystyle} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    {layer.src ?
                        <Img src={layer.src}
                            style={shapeStyle}
                        /> :
                        <span style={shapeStyle}></span>
                    }
                </Animation>
            </AbsoluteFill>
        </Sequence>
    )
}

export default ShapeLayer
