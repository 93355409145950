import React, { useEffect, useState } from 'react';
import TitleBar from '../../../../title-bar/TitleBar';
import Header from '../../../../header/Header';
import Footer from '../../../../footer/Footer';


import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { onFetchScript, onGetScript, onUpdateScript } from '../../../../../Redux/Actions/ProjectActions';
import { setAlert } from '../../../../../Redux/Actions/AlertActions';
import ScriptLeftPanel from './ScriptLeftPanel';
import ScriptRightPanel from './ScriptRightPanel';

const CreateScript = () => {
  const characterLimit = 12000
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { cid } = queryString.parse(location.search)

  const [scriptText, setScriptText] = useState("")
  const [createData, setCreateData] = useState(false)
  const [activeInterval, setActiveInterval] = useState(false)
  const [pageLoader, setPageLoader] = useState(true)

  const [buttonLoader, setButtonLoader] = useState({
    save: false,
    next: false
  })


  const handleSubmit = (type) => {
    if (scriptText) {
      let data = {
        cId: cid,
        savedScript: scriptText,
        type: type
      }
      setButtonLoader({
        ...buttonLoader,
        next: type === "save" ? false : true,
        save: type === "save" ? true : false
      })
      dispatch(onUpdateScript(data, navigate, buttonLoader, setButtonLoader))
    } else {
      dispatch(setAlert("Please provide script!", "danger"))
    }
  }



  const fetchCampaign = () => {
    let data = {
      cId: cid
    }
    dispatch(onFetchScript(data, setScriptText, setCreateData, setActiveInterval, setPageLoader))
  }

  const handleGetScript = (interval) => {
    let data = {
      cId: cid
    }
    dispatch(onGetScript(data, setScriptText, setCreateData, setActiveInterval, interval))
  }

  useEffect(() => {
    if (cid) {
      fetchCampaign()
    }
  }, [cid])

  useEffect(() => {
    if (activeInterval) {
      const interval = setInterval(() => {
        handleGetScript(interval)
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [activeInterval])

  return (
    pageLoader ?
      <div className="loader-sec">
        <div className="loader" />
      </div>
      :
      <>
        <TitleBar
          title="Create Script"
        />
        <Header />

        <section className='page-wrap full'>
          <div className='breadCr'>
            <div className='container'>
              <ul>
                <li><Link to="/home">Dashboard</Link></li>
                <li>/</li>
                <li><Link to={`/projects/create-video?cid=${cid}`}>Create Video</Link></li>
                <li>/</li>
                <li><Link to={`/projects/choose-template?cid=${cid}`}>Templates</Link></li>
                <li>/</li>
                <li><Link to={`/projects/choose-character?cid=${cid}`}>Characters</Link></li>
                <li>/</li>
                <li><span style={{ color: "#f7ab3a", fontWeight: 600 }}>Create Script</span></li>
              </ul>
            </div>
          </div>

          <div className='container'>
            <div className='flex-bar'>
              <h4 style={{ textTransform: "capitalize" }}>
                {createData.campaign.name}
              </h4>

              <div className='d-flex align-items-center justify-content-center flex-wrap gap-2'>
                <button
                  className='site-link bdr'
                  type='button'
                  onClick={() => handleSubmit("save")}
                // style={scriptText ?  scriptText.length > characterLimit.limit ? { background: "gray", borderColor: "gray" } : {} : {}}
                >
                  <span>{buttonLoader.save ? <>Saving <i className="fa fa-spinner fa-spin ms-1" /></> : "Save Script"}</span>
                </button>

                <button
                  className='site-link'
                  type='button'
                  onClick={() => handleSubmit("createVideo")}
                // style={scriptText ?  scriptText.length > characterLimit.limit ? { background: "gray" } : {} : {}}
                >
                  <span>{buttonLoader.next ? <>Converting <i className="fa fa-spinner fa-spin ms-1" /></> : "Convert to Video"}</span>
                </button>


              </div>
            </div>

            <div className='container-fluid-sm'>
              <div className='row align-items-center'>
                <ScriptLeftPanel
                  createData={createData}
                  setCreateData={setCreateData}
                  fetchCampaign={fetchCampaign}
                  activeInterval={activeInterval}
                />

                <ScriptRightPanel
                  scriptText={scriptText}
                  setScriptText={setScriptText}
                  activeInterval={activeInterval}
                />
              </div>
            </div>

          </div>
        </section>
        <Footer />
      </>
  )

}

export default CreateScript;