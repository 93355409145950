import React from 'react'

const AISocialsReputor = () => {
    return (
        <>
            <div className="tabepaneIn ">
                <h2 className="tabTitle">
                    AISocials Reputor
                </h2>
                <div className="tabepaneIn-main">
                    {/* <div className="tabepaneIn-cont"> */}
                    {/* <h3>
AISocials Reputor
</h3> */}
                    <div className="col-md-12">
                        <div className=" text-center  text-white">
                            <div className="">
                                <a
                                    rel="noreferrer"
                                    href="https://virtualpioneer.lpages.co/aisocialsreputorspecial/"
                                    target="_blank"
                                >
                                    <button className="site-link mt-0">

                                        <span> AISocials Reputor</span>
                                    </button>
                                </a>
                            </div>
                        </div>

                    </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

export default AISocialsReputor
