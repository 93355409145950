import React, { useEffect, useState } from "react";
import CreateMemModal from "./CreateMemModal";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import MemberRows from "./MemberRows";
import { fetchData } from "../../Redux/Actions/CommonActions";


const WhiteLabelMember = () => {
    const dispatch = useDispatch()
    const isWhiteLabel = useSelector(state => state.wl.data.isWhiteLabel)
    console.log(useSelector(state => state.wl.data.isWhiteLabel))
    const [memberships, setMemberships] = useState([])

    const [loader, setLoader] = useState({
        fetch: true
    })

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const fetchMemberships = () => {
        let data = {}
        dispatch(fetchData("fetch-membership", data, setMemberships, loader, setLoader))
    }

    useEffect(() => {
        fetchMemberships()
    }, [])

    return (
        <>

            <div className="White_label_wrap">
                <div className="white_Customization_btn">
                    <div className="white-titleBar-left mb-3 mb-sm-0">
                        <h2>Membership</h2>
                        <p>Create and Manage Memberships</p>
                    </div>
                    <div className="titleBar-right">
                        <button
                            className="white-theme-btn"
                            onClick={handleShow}
                            disabled={!isWhiteLabel}
                            style={{ background: isWhiteLabel ? "" : "gray", cursor: isWhiteLabel ? "" : "not-allowed" }}

                        ><span>Create Membership</span></button>
                    </div>
                </div>

                <div className="white-block">
                    {/* <div className="white-titleBar-left sm pb-4">
                        <h2>Members</h2>
                    </div> */}
                    <div className="white-table-responsive table-responsive">
                        <table className="table table-bordered white-table" style={{ minWidth: "350px" }}>
                            <tr>
                                <th>Name</th>
                                <th>Price ($)</th>
                                <th className="text-end">Actions</th>
                            </tr>
                            {memberships.length > 0 ?
                                memberships.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <MemberRows
                                                curElem={curElem}
                                                fetchMemberships={fetchMemberships}
                                                memberships={memberships}
                                                setMemberships={setMemberships}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                :
                                <tr className="text-center mt-2">
                                    <td colSpan={3}>
                                        {loader.fetch ?
                                            <FaSpinner size={25} className="spin p-0" /> : "You do not have membership created!"}
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>

                </div>
            </div>
            <CreateMemModal
                show={show}
                handleClose={handleClose}
                fetchMemberships={fetchMemberships}
            />

        </>
    )
}


export default WhiteLabelMember;