import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onUpdateCampaign } from '../../../../../Redux/Actions/ProjectActions'

const ScriptLeftPanel = ({ createData, setCreateData, fetchCampaign, activeInterval }) => {
    const dispatch = useDispatch()
    const [regenerateLoader, setRegenerateLoader] = useState(false)

    const handleUse = (curElem) => {
        setCreateData({
            ...createData,
            selectedIdea: curElem
        })
    }

    const regenerateScript = () => {
        setRegenerateLoader(true)
        let data = {
            ...createData.campaign,
            results: JSON.parse(createData.campaign.results),
            selected: createData.selectedIdea
        }
        dispatch(onUpdateCampaign(data, false, setRegenerateLoader, fetchCampaign))
    }

    return (
        <div className='col-xl-6 col-12'>
            <div className='inp-bg-left'>
                <div className='cont-box sm-gap'>
                    <div className='content-list'>
                        <ul>
                            <li>
                                <textarea
                                    placeholder='Enter text to generate ideas'
                                    className='common-input'
                                    type="text"
                                    value={createData.selectedIdea}
                                    readOnly
                                    style={{ maxHeight: 120 }}
                                />
                                <button style={{width:"100%", textAlign:"center"}} className={activeInterval ? "site-link-gray" : 'site-link'} type='button' onClick={regenerateScript} disabled={regenerateLoader || activeInterval}>
                                    <span>
                                        {regenerateLoader ? <>Regenerating <i className="fa fa-spinner fa-spin ms-1" /></> : "Regenerate Script"}
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='cont-box sm-gap mt-4'>
                    <div className='recommend-list'>
                        <ul>
                            {
                                JSON.parse(createData.campaign.results).map((curElem, index) => {
                                    return (
                                        <li key={index}>
                                            <div className='recommend-list-left'>{curElem}</div>
                                            <button
                                                type='button'
                                                className={createData.selectedIdea === curElem ? "site-link-gray" : "site-link"}
                                                onClick={() => handleUse(curElem)}
                                                disabled={createData.selectedIdea === curElem}
                                            >
                                                <span>
                                                    {createData.selectedIdea === curElem ? "Used" : "Use This"}
                                                </span>
                                            </button>
                                        </li>
                                    )
                                })

                            }

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScriptLeftPanel