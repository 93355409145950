import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { forgetPassword } from '../../../Redux/Actions/AuthActions';
import { useDispatch } from 'react-redux';

import Footer from '../../footer/Footer';
import TitleBar from '../../title-bar/TitleBar';
import { appName } from '../../../Global/Global';
import { useSelector } from 'react-redux';

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState("")
    const rebrand = useSelector(state => state.rebrand)

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(forgetPassword(data, setLoader))
    }
    return (
        <>
            <TitleBar title="Forgot Password" />
            <div className="login-wrap">
                <div className="login-head">
                    <div className="login-logo"><img alt='' src={require('../../../assets/images/logo.png')} /></div>
                </div>

                <div className="login-left">
                    <div className='welcome-txt'>
                        <h2>Welcome To {rebrand?.data?.reseller?.name || "ToonReel"}</h2>
                        <p>Transform ideas into Pixar-quality animated videos with AI magic! Easy, fast, and fun cartoon video creation with {appName}. Let’s get started!</p>
                    </div>
                </div>
                <div className="login-right">
                    <form className="login-right-main" onSubmit={handleSubmit}>
                        <h2>Enter Your Email Address</h2>
                        <div className='common-input-wrap'>
                            <label htmlFor="">Email Address</label>
                            <input
                                className='common-input'
                                type="email"
                                name="email"
                                value={email}
                                placeholder='Email Address'
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='common-input-wrap'>
                            <button className='site-link full' type='submit'><span>{loader ? <>Submitting <i className="fa fa-spinner fa-spin mx-1" /></> : "Submit"}</span></button>
                        </div>
                        <div className='common-input-wrap text-center'>
                            <p><Link to="/login">Remember Login?</Link></p>
                        </div>
                    </form>
                </div>
            </div>

            <div className='loginFooter'>
                <Footer />
            </div>
        </>
    )

}

export default ForgotPassword;