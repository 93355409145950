import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";

const TitleBar = (props) => {
    const rebrand = useSelector(state => state.rebrand)

    return (
        <>
            <Helmet>
                <title>{rebrand.reseller?.data ? rebrand?.reselle?.data.name : appName} - {props.title}</title>
            </Helmet>
        </>
    )
}

export default TitleBar;