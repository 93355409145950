import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { connectSocial } from '../../../Redux/Actions/SocialActions';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';

const ConnectModal = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const [appDetails, setAppDetails] = useState({
        user_id: auth.user.id,
        app_name: props.type,
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setAppDetails({
            ...appDetails,
            [name]: value
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(connectSocial(appDetails, props.name, props.handleClose, setLoader))
    }



    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div> 
                <form onSubmit={onFormSubmit}>

                    <div className="connect-modal">
                        <h3>Connect {props.name}</h3>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Email</label>
                                    <input
                                        className='common-input'
                                        type="email"
                                        placeholder="Enter Email"
                                        name='email'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Password</label>
                                    <input
                                        className='common-input'
                                        type="password"
                                        placeholder="Enter Password"
                                        onChange={handleChange}
                                        name='password'
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='mt-3' style={{ display: "flex", justifyContent: "right" }}>
                        <button type='button' className='site-link bdr' onClick={props.handleClose}><span>Cancel</span></button>
                        <button className='ms-2 site-link' type='submit'><span>{loader ? <>Connecting <i className="fa fa-spinner fa-spin mx-1" /></> : "Connect"}</span></button>

                    </div>
                </form>
            </Modal.Body>

        </Modal>
    )
}

export default ConnectModal