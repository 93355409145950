import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { useDispatch } from 'react-redux'
import rgbHex from 'rgb-hex'
import { onChangeGradient } from '../../../../Redux/Actions/VideoActions'

const Gradient = (props) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [colVal, setColVal] = useState({
        hex: "",
    })

    const handleColor = (e) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        dispatch(onChangeGradient(hex, props.type, props.selectedSlideIndex, props.textIndex))

    }

    useEffect(() => {
        if (props.styleState) {
            let bg = props.styleState.background
            bg = bg.split(",")
            if (bg.length > 0) {
                if (props.type === "left") {
                    let leftStuff = bg[1]
                    leftStuff = leftStuff.split(" ")
                    setColVal({
                        ...colVal,
                        hex: leftStuff[1]
                    })
                } else {
                    let rightStuff = bg[2]
                    rightStuff = rightStuff.split(" ")
                    setColVal({
                        ...colVal,
                        hex: rightStuff[1]
                    })
                }
            }
        }
    }, [props.styleState])


    return (

        <div className="col-6">
            <h6 className='text-capitalize'>Color {props.type}</h6>
            <div className="color-indicator mt-2">
                <span
                    className="color-box"
                >
                    <div
                        onClick={() => setShow(true)} style={{ width: "100%", height: "100%", background: colVal.hex, cursor: 'pointer' }}
                    >
                    </div>
                    {show ?
                        <>
                            <div style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }} onClick={() => setShow(false)} />
                            <SketchPicker
                                color={colVal.hex}
                                onChange={(e) => handleColor(e)}
                                className={`${props.type === "right" ? "sketch_right" : ""}`}
                            />
                        </>
                        : ""
                    }
                </span>
                <span className="color-val">{colVal.hex}</span>
            </div>
        </div>

    )
}

export default Gradient