import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Rnd } from "react-rnd";
import { endAnimation, onGenerateXmlData } from '../../../../Redux/Actions/VideoActions';
import axios from 'axios';
const CharacterLayer = (props) => {

    let ediotWidth = props.dimension === "9x16" ? 360 : 853
    let ediotHeight = props.dimension === "9x16" ? 640 : 480

    let newLeft = (props.layer.position.left * ediotWidth) / 100
    let newTop = (props.layer.position.top * ediotHeight) / 100

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [xmlData, setXmlData] = useState("");
    const [myStyle, setMyStyle] = useState({
        left: props.layer.position.left,
        top: props.layer.position.top,
        width: props.layer.size.width,
        height: props.layer.size.height,
        animationDuration: props.layer.animation?.duration,
        animationDelay: props.layer.animation?.delay,
        position: "absolute",
    })

    const handleAnimEnd = () => {
        dispatch(endAnimation(props.selectedSlideIndex, props.layerIndex))
    }

    useEffect(() => {

        let newLeft = (props.layer.position.left * ediotWidth) / 100
        let newTop = (props.layer.position.top * ediotHeight) / 100

        const obj = {
            left: newLeft,
            top: newTop,
            width: `${props.layer.size.width}%`,
            height: `${props.layer.size.height}%`,
            animationDuration: props.layer.animation?.duration,
            animationDelay: props.layer.animation?.delay,
            position: "absolute",
        }
        setMyStyle(obj)
    }, [props.layer.position, props.layer.size, props.layer.animation])

    const imageStyle = {
        display: "block",
        pointerEvents: "none",
        height: "100%",
        width: "100%",
        animationDelay: myStyle.animationDelay,
        animationDuration: myStyle.animationDuration,
        position: "relative"
    }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         let skin = document.getElementById("myIframe").contentWindow.document
    //         const serializer = new XMLSerializer();
    //         let xmlDataParsed = serializer.serializeToString(skin)
    //         // dispatch(onGenerateXmlData(xmlDataParsed, props.selectedSlideIndex, props.layerIndex))
    //         // setXmlData(skin)
    //     }, 2000)
    //     setTimeout(() => {
    //         clearInterval(interval)
    //     }, 6000)
    //     return () => {
    //         clearInterval(interval)
    //     }
    // }, [props.layer.animation.playAnimation])

    const getSvgColor = (xmlDoc, className) => {
        let element = xmlDoc.children[0].getElementsByClassName(className)
        let obj = {
            color: element[0]?.getAttribute("fill") ? element[0]?.getAttribute("fill") : "#000000",
            enable: element.length > 0 ? true : false
        }
        return obj
    }

    useEffect(() => {
        if (!props.layer.charString) {
            const fetchData = async () => {
                await axios.get(props.layer.src + "?cacheblock=true").then(data => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(data.data, 'text/xml');

                    let charColors = {
                        hairColor: getSvgColor(xmlDoc, "character_hairColor"),
                        skinColor: getSvgColor(xmlDoc, "character_skinColor"),
                        topwearColor: getSvgColor(xmlDoc, "character_topwearColor"),
                        topwear1Color: getSvgColor(xmlDoc, "character_topwear1Color"),
                        topwear2Color: getSvgColor(xmlDoc, "character_topwear2Color"),
                        bottomwearColor: getSvgColor(xmlDoc, "character_bottomwearColor"),
                        shoesColor: getSvgColor(xmlDoc, "character_ShoesColor"),
                        accessoriesColor: getSvgColor(xmlDoc, "character_accessoriesColor"),
                    }
                    xmlDoc.children[0].style.height = "100%"
                    xmlDoc.children[0].style.width = "100%"
                    const serializer = new XMLSerializer();
                    let xmlDataParsed = serializer.serializeToString(xmlDoc)
                    dispatch(onGenerateXmlData(xmlDataParsed, charColors, props.selectedSlideIndex, props.layerIndex))

                }).catch(error => {
                    console.error('Error fetching XML:', error);
                });
            }
            fetchData()
        }
    }, [props.layer.src]);

    useEffect(() => {
        let myIframe = document.getElementById('myIframe');
        if (myIframe) {
            let iframeDocument = myIframe.contentDocument || myIframe.contentWindow.document;
            let iframeHtml = iframeDocument.documentElement;
            let iframeBody = iframeDocument.body;
            if (iframeHtml) {
                iframeHtml.style.width = '100%';
                iframeHtml.style.height = '100%';
                iframeHtml.style.display = "flex";
                iframeHtml.style.alignItems = "center";
                iframeHtml.style.justifyContent = "center";
            }
            if (iframeBody) {
                iframeBody.style.width = '100%';
                iframeBody.style.height = '100%';
            }
        }
    }, [props.layer])
    // if (props.layer) {
    //     console.log(props.layer.position)
    //     console.log(props.layer.size)
    // }

    return (
        <>
            <Rnd
                style={{ overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}
                enableResizing={props.layer.isSelected ? true : false}
                disableDragging={props.layer.isSelected ? false : true}
                enableUserSelectHack={true}
                onClick={() => props.handleSelectLayer(props.layerIndex)}
                default={{ x: 0, y: 0, height: myStyle.height }}
                size={{ height: `${props.layer.size.height}%`, width: `${props.layer.size.width}%` }}
                position={{ x: newLeft, y: newTop }}
                dragGrid={[1, 1]}
                onDragStop={(e, d) => {
                    let rndLeft = (d.x / ediotWidth) * 100
                    let rndTop = (d.y / ediotHeight) * 100
                    props.handleRePosition(rndTop, rndLeft, props.layerIndex)
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    let rndLeft = (position.x / ediotWidth) * 100
                    let rndTop = (position.y / ediotHeight) * 100
                    props.handleResize(ref.style.width, ref.style.height, rndLeft, rndTop, props.layerIndex)
                }}
                // bounds=".canvasEditor-main"

                className={props.layer.isSelected ? "layer-border" : ""}
                // data-html2canvas-ignore="true"
                onMouseEnter={(e) => e.stopPropagation()}
            >
                {/* {
                    loader ?
                        <div className='media-loader' style={{ position: "absolute" }}>
                            <FaSpinner className='spin' />
                        </div>
                        : ""
                } */}
                <span
                    className={`animate__animated ${props.layer.animation.playAnimation ? props.layer.animation.name : ""} ${loader ? 'invisible' : ""}`}
                    style={imageStyle}
                    onAnimationEnd={() => handleAnimEnd()}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                    />

                    <iframe id='myIframe' scrolling="no" style={{ pointerEvents: "none", height: "100%", width: "100%", overflow: "hidden" }} srcDoc={props.layer.charString} />
                </span>
            </Rnd >
        </>
    )
}

export default CharacterLayer