import React from 'react'
import { AbsoluteFill, Sequence } from 'remotion';
import WebFont from 'webfontloader';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';


const TextLayer = ({ layer, multiplyNum }) => {

    WebFont.load({
        google: {
            families: [`${layer.style.fontFamily}`]
        }
    });

    function increaseSize(size) {
        if (size) {
            let matches = size.match(/(\d+)/);
            let actualSize = parseInt(matches[0]) * multiplyNum;
            return `${actualSize}px`
        }
    }

    function increasePadding(pad) {
        if (pad) {
            let arr = pad.split(" ")
            let finalPadding = ""
            arr.forEach(element => {
                if (element) {
                    finalPadding += increaseSize(element) + " "
                }
            });
            return finalPadding
        }
    }

    let html = layer.src;

    let mystyle = {
        position: `absolute`,
        background: `${layer.style.background}`,
        opacity: `${layer.style.opacity}`,
        border: `${layer.style.border}`,
        borderTop: `${layer.style.borderTop}`,
        borderBottom: `${layer.style.borderBottom}`,
        borderRight: `${layer.style.borderRight}`,
        borderLeft: `${layer.style.borderLeft}`,
        borderWidth: `${increaseSize(layer.style.border ? layer.style.border : layer.style.borderTop ? layer.style.borderTop : layer.style.borderBottom ? layer.style.borderBottom : layer.style.borderRight ? layer.style.borderRight : layer.style.borderLeft)}`,
        boxShadow: `${layer.style.boxShadow}`,
        objectFit: `${layer.style.objectFit}`,
        left: `${+layer.position.left}%`,
        top: `${+layer.position.top}%`,
        width: `${+layer.size.width}%`,
        height: `${+layer.size.height}%`,
        textAlign: `${layer.style.textAlign}`,
        overflow: "hidden",
        fontSize: `${+layer.style.fontSize * multiplyNum}px`,
        fontFamily: `${layer.style.fontFamily}`,
        fontWeight: `${layer.style.fontWeight}`,
        textTransform: `${layer.style.textTransform}`,
        textDecoration: `${layer.style.textDecoration}`,
        fontFamily: `${layer.style.fontFamily}`,
        fontStyle: `${layer.style.fontStyle}`,
        lineHeight: `${layer.style.lineHeight}`,
        textShadow: `${layer.style.textShadow}`,
        letterSpacing: `${layer.style.letterSpacing * multiplyNum}px`,
        wordSpacing: `${layer.style.wordSpacing * multiplyNum}px`,

        borderTopRightRadius: `${layer.style.borderTopRightRadius === undefined ? '' : increaseSize(layer.style.borderTopRightRadius)}`,
        borderBottomRightRadius: `${layer.style.borderBottomRightRadius === undefined ? '' : increaseSize(layer.style.borderBottomRightRadius)}`,
        borderTopLeftRadius: `${layer.style.borderTopLeftRadius === undefined ? '' : increaseSize(layer.style.borderTopLeftRadius)}`,
        borderBottomLeftRadius: `${layer.style.borderBottomLeftRadius === undefined ? '' : increaseSize(layer.style.borderBottomLeftRadius)}`,
        borderRadius: layer.style.borderRadius ? `${layer.style.borderRadius}%` : "",
    }

    let spanStyle = {
        display: "block",
        color: `${layer.style.color}`,
    }


    if (layer.textToHighlight !== null && layer.textToHighlight !== undefined) {
        layer.textToHighlight.forEach((curElem) => {
            html = html.replaceAll(curElem.text, `<span style="color:${curElem.color}; background:${curElem.background};">${curElem.text}</span>`);
        })
    }

    if (layer.innerCss && layer.innerCss != null && layer.innerCss != undefined) {
        spanStyle = {
            ...spanStyle,
            display: `${layer.innerCss.display ? layer.innerCss.display : "block"}`,
            padding: layer.innerCss.padding ? layer.innerCss.padding : "",
            lineHeight: `${layer.innerCss.lineHeight ? layer.innerCss.lineHeight : ""}`,
            background: `${layer.innerCss.background ? layer.innerCss.background : ""} `,
            webkitBoxDecorationBreak: layer.innerCss.webkitBoxDecorationBreak ? layer.innerCss.webkitBoxDecorationBreak : "",
            whiteSpace: layer.innerCss.whiteSpace ? layer.innerCss.whiteSpace : "",
        }
    }
    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill>
                <Animation style={mystyle} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    <span style={spanStyle} dangerouslySetInnerHTML={{ __html: html }} ></span>
                </Animation>
            </AbsoluteFill >
        </Sequence>
    )
}

export default TextLayer
