import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { deleteSocialAccounts, saveYoutube } from "../../../Redux/Actions/SocialActions";
import SweetAlert from "../../CommonComponent/SweetAlert";

const ConnectYoutube = (props) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const deleteIntegration = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: sweet.id,
            user_id: auth.user.id
        }
        dispatch(deleteSocialAccounts("delete-social-network", data, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const responseGoogle = (response) => {
        if (response.code) {
            let data = { code: response.code, user_id: auth.user.id }
            dispatch(saveYoutube(data))
        } else {
            console.log(response);
        }
    }

    return (


        <>
            <div className="mt-3" id="youtube">
                <div>
                    {
                        props.data.length > 0 ?
                            <div className="connection-single" style={{ display: "block" }}>
                                {
                                    props.data.map((item, index) => {
                                        return (
                                            <>
                                                <div key={index} className="facebook-connect-account  d-flex justify-content-between  align-items-center mb-4">
                                                    <div className="connection-left">
                                                        <div className={`connection-logo ${props.data.length > 0 ? '' : 'disable-img'}`}>
                                                            <img src={require('../../../assets/images/icon/youtube.png')} alt="facebook" />
                                                        </div>
                                                        <div>
                                                            <p><strong>Youtube</strong></p>
                                                            <p className="m-0">{item?.username} - Added on {item.created}</p>
                                                        </div>
                                                    </div>

                                                    <div className="facebook-delete">
                                                        <button type="button" onClick={(e) => deleteIntegration(item.id)} className="site-link"><span>Disconnect</span></button>
                                                    </div>
                                                </div>
                                                {index === props.data.length - 1 ?
                                                    <div className="connection-right facebook-connect-account d-flex justify-content-center">
                                                        <GoogleLogin
                                                            clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                                            render={(renderProps) => (
                                                                <button onClick={renderProps.onClick} type="button" className="site-link"> <span>Connect New Account</span></button>
                                                            )}
                                                            onSuccess={responseGoogle}
                                                            onFailure={responseGoogle}
                                                            scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                                            responseType="code"
                                                            accessType="offline"
                                                            prompt="consent"
                                                            cookiePolicy={"single_host_origin"}
                                                        />
                                                    </div>
                                                    : ""}
                                            </>
                                        )

                                    })}
                            </div>
                            :
                            <div className="connection-single" style={{ flexDirection: "row" }}>
                                <div className="connection-left">
                                    <div className={`connection-logo ${props.data.length > 0 ? '' : 'disable-img'}`}>
                                        <img src={require('../../../assets/images/icon/youtube.png')} className={props.data.length > 0 ? "" : "disable"} alt="facebook" />
                                    </div>
                                    <p><strong>Youtube</strong></p>
                                </div>
                                <div className="connection-right">
                                    <div className="connection-right">
                                        <GoogleLogin
                                            clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                            render={(renderProps) => (
                                                <button onClick={renderProps.onClick} type="button" className="site-link"> <span>Connect</span></button>
                                            )}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                            responseType="code"
                                            accessType="offline"
                                            prompt="consent"
                                            cookiePolicy={"single_host_origin"}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>


            <SweetAlert
                show={sweet.enable}
                message={"Are you sure? Do you want to delete this youtube account?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                alertLoader={sweet.loader}
                performDelete={onConfirm}

            />

        </>
    )
}

export default ConnectYoutube;