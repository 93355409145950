import React, { useEffect, useRef, useState } from 'react'
import { AbsoluteFill, Sequence, IFrame, Series } from 'remotion';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';


const CharacterLayer = ({ layer, multiplyNum, duration }) => {

    const [repeat, seRepeat] = useState(Math.round(duration / 2))
    const [loader, setLoader] = useState(false)
    const myIframeRef = useRef(null);
    const iframeHtmlRef = useRef(null);
    const iframeBodyRef = useRef(null);

    let mystyle = {
        position: "absolute",
        left: `${+layer.position.left}%`,
        top: `${+layer.position.top}%`,
        width: `${+layer.size.width}%`,
        height: `${+layer.size.height}%`,
    }
    let iFrameStyle = {
        position: "absolute",
        width: "100%",
        height: "100%",
    }

    useEffect(() => {
        if (layer.type === "character") {
            setLoader(true)
        }
    }, [layer.type]);

    const handleIframeLoad = () => {
        setLoader(false);

        const myIframe = myIframeRef.current;
        if (myIframe) {
            const iframeDocument = myIframe.contentDocument || myIframe.contentWindow.document;

            if (iframeDocument) {
                const iframeHtml = iframeDocument.documentElement;
                const iframeBody = iframeDocument.body;

                iframeHtmlRef.current = iframeHtml;
                iframeBodyRef.current = iframeBody;

                if (iframeHtml) {
                    iframeHtml.style.width = '100%';
                    iframeHtml.style.height = '100%';
                    iframeHtml.style.display = 'flex';
                    iframeHtml.style.alignItems = 'center';
                    iframeHtml.style.justifyContent = 'center';
                }

                if (iframeBody) {
                    iframeBody.style.width = '100%';
                    iframeBody.style.height = '100%';
                }
            }
        }
    };

    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill>
                {
                    loader ?
                        <div className='loader-center-temp1' style={{ position: "absolute", left: `${layer.position.left}%`, top: `${layer.position.top}%`, height: `${layer.size.height}%`, width: `${layer.size.width}%` }} >
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px" }} />
                        </div> : ""
                }
                <Animation style={mystyle} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    <Series>
                        {
                            [...Array(repeat)].map((ele, repeatIndex) => {
                                return (
                                    <React.Fragment key={repeatIndex}>
                                        <Series.Sequence durationInFrames={2 * 30}>
                                            {
                                                layer.charString ?
                                                    <IFrame ref={myIframeRef} scrolling="no" key={layer.charString} style={iFrameStyle} srcDoc={layer.charString} frameborder="0" onLoad={handleIframeLoad}></IFrame>
                                                    :
                                                    <IFrame ref={myIframeRef} scrolling="no" key={layer.src} style={iFrameStyle} src={layer.src} frameborder="0" onLoad={handleIframeLoad}></IFrame>
                                            }
                                        </Series.Sequence>
                                    </React.Fragment>
                                )
                            })
                        }
                    </Series>
                </Animation>
            </AbsoluteFill>
        </Sequence>
    )
}

export default CharacterLayer