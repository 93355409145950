import React from "react";
import { BsPlayCircle } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";
// import { addSlideDuration, muteVideoAudio, unMuteVideoAudio } from "../../../../Redux/Actions/VideoActions";
// import { setAlert } from "../../../../Redux/Actions/AlertActions";
import LogoWatermark from "./LogoWatermark";
import { VscMute, VscUnmute } from "react-icons/vsc"
import VolumeControl from "./VolumeControl";
import Prompt from "./Prompt";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import { addSlideDuration, muteVideoAudio, unMuteVideoAudio } from "../../../../Redux/Actions/VideoActions";

const Setting = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

    const logo = campaign.logo
    const watermark = campaign.watermark

    const addDuration = (e) => {
        let num = e.target.value
        if (num > 300) {
            dispatch(setAlert("You can't set slide duration more than 300 sec.", "warning"))
            dispatch(addSlideDuration(300))
        } else {
            dispatch(addSlideDuration(num))
        }
    }

    const handleMuteVideoLayer = (data) => {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (data.mute === false) {
            dispatch(muteVideoAudio(selectedSlideIndex, selectedLayerIndex))
        }
    }

    function hasAudio(video) {
        return video.mozHasAudio ||
            Boolean(video.webkitAudioDecodedByteCount) ||
            Boolean(video.audioTracks && video.audioTracks.length);
    }

    const handleUnMuteVideoLayer = (selectedLayer) => {
        let video = document.querySelector('#checkAudioData');
        video.play()
        video.muted = true
        setTimeout(() => {
            let data = hasAudio(video)
            if (data) {
                const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
                if (selectedLayer.mute) {
                    dispatch(unMuteVideoAudio(selectedSlideIndex, selectedLayerIndex))
                }
            } else {
                dispatch(setAlert("This video does not have an audio!", "danger"))
            }
            video.pause()
        }, 1000)
    }

    return (
        <>
            <h2 className='tab-title'>Settings</h2>
            <div className="left-scroll">

                <LogoWatermark
                    type={"logo"}
                    data={logo}
                />
                <hr />
                <LogoWatermark
                    type={"watermark"}
                    data={watermark}
                />

                <hr />
                <div className="font-block mt-4">
                    <h5>Duration(S)</h5>
                    <input
                        className="inp-small mt-2"
                        name='duration'
                        type="number"
                        min={1}
                        value={selectedSlide.duration}
                        onChange={(e) => addDuration(e)}
                    />
                </div>
                <hr />

                {selectedLayer ?
                    selectedLayer.type === "video" ?
                        <>
                            <div className='d-flex justify-content-between align-items-center  mt-4'>
                                <h6>Mute/Unmute Video</h6>
                                {selectedLayer.mute ?
                                    <VscMute
                                        size={25}
                                        onClick={() => handleUnMuteVideoLayer(selectedLayer)}
                                        color="#f7ab3a"
                                        style={{cursor:"pointer"}}
                                    /> :
                                    <VscUnmute
                                        size={25}
                                        onClick={() => handleMuteVideoLayer(selectedLayer)}
                                        style={{cursor:"pointer"}}
                                        color="#f7ab3a"
                                    />
                                }

                            </div>
                            <hr />
                        </>
                        : "" : ""}



                <VolumeControl />
                <hr />

                <Prompt
                    promo={campaign.promo}
                />

                {selectedLayer ?
                    selectedLayer.type === "video" ?
                        <video
                            className="no-showvideotag"
                            id="checkAudioData"
                            controls
                            src={selectedLayer.src ? selectedLayer.src : ""}
                        /> : ""

                    : ""
                }




            </div>
        </>
    )
}

export default Setting;