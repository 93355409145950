import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { SketchPicker } from "react-color";
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import { addTextHighlight, onFontBgColor, onFontColor, removeHighlightColor, updateTextHighlight } from '../../../../Redux/Actions/VideoActions';
import { colorObj } from '../../../../Global/ColorCodes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsQuestionCircle } from 'react-icons/bs';

const FontColor = (props) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [colVal, setColVal] = useState({
        hex: "",
        r: "",
        g: "",
        b: "",
        a: ""
    })
    const handleChooseColor = (curElem, index) => {
        if (props.isHighlight) {
            if (props.dataHigh.text !== "") {
                const arrIndex = props.selectedText.textToHighlight.findIndex(({ text }) => text === props.dataHigh.text)

                if (index === 0) {
                    removeBgColor(arrIndex)
                }
                else {
                    setColVal({
                        ...colVal,
                        hex: curElem.hex,
                        r: curElem.rgb.r,
                        g: curElem.rgb.g,
                        b: curElem.rgb.b,
                        a: curElem.rgb.a
                    })
                    if (arrIndex === -1) {
                        let data = {
                            text: props.dataHigh.text,
                            color: props.type === "highlight" ? curElem.hex : props.dataHigh.color,
                            background: props.type === "highlight background" ? curElem.hex : props.dataHigh.background
                        }
                        props.setDataHigh(data)
                        dispatch(addTextHighlight(data, props.selectedSlideIndex, props.textIndex));
                    }
                    else {
                        if (props.type === "highlight") {
                            props.setDataHigh({
                                ...props.dataHigh,
                                color: curElem.hex
                            })
                        } else {
                            props.setDataHigh({
                                ...props.dataHigh,
                                background: curElem.hex
                            })
                        }

                        dispatch(updateTextHighlight(curElem.hex, props.type, arrIndex, props.selectedSlideIndex, props.textIndex));
                    }
                }
            }
        } else {
            let rgba = `rgba(${curElem.rgb.r}, ${curElem.rgb.g}, ${curElem.rgb.b}, ${curElem.rgb.a.toFixed(2)})`
            let hex = `#${rgbHex(rgba)}`

            let val = hex
            if (props.type === "text") {
                dispatch(onFontColor(val, props.selectedSlideIndex, props.textIndex));

            } else {
                if (index === 0) {
                    val = ""
                }
                dispatch(onFontBgColor(val, props.selectedSlideIndex, props.textIndex));
            }
        }

    }

    const removeBgColor = (index) => {
        dispatch(removeHighlightColor(props.type, index, props.selectedSlideIndex, props.textIndex));
    }

    useEffect(() => {
        if (props.color) {
            if (props.color.includes("#")) {
                let hex = hexRgb(props.color)
                setColVal({
                    ...colVal,
                    hex: props.color,
                    r: hex.red,
                    g: hex.green,
                    b: hex.blue,
                    a: hex.alpha
                })
            }
        }
    }, [props])



    return (
        <div className="font-block mt-4">
            <div className='d-flex align-items-center justify-content-between'>
                <h5 className='text-capitalize'>{props.type} Color</h5>
                {props.isHighlight ?
                    <span className='' >
                        <OverlayTrigger
                            placement={"top"}
                            overlay={
                                <Tooltip id="tooltip-top" >
                                    Please select words first to highlight it.
                                </Tooltip>
                            }
                        >
                            <p style={{ display: "inline-block", position: "relative", top: "-1px" }}> <BsQuestionCircle color='#f7ab3a' className='mb-0 ms-1' size={17} cursor="help" /></p>
                        </OverlayTrigger>
                    </span> : null}
            </div>
            <div className="font-color pt-2">
                <div className="row sm">
                    <div className="col-6">
                        <h6>HEX</h6>
                        <div className="color-indicator mt-2">
                            <span
                                className="color-box"
                            >
                                <div onClick={() => setShow(true)} style={{ width: "100%", height: "100%", background: props.isHighlight ? props.type === "highlight" ? props.dataHigh.color : props.dataHigh.background : props.color, cursor: 'pointer' }} >
                                </div>
                                {show ?
                                    <>
                                        <div style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }} onClick={() => setShow(false)} />
                                        <SketchPicker
                                            color={colVal.hex}
                                            onChange={(e) => handleChooseColor(e)}
                                        />
                                    </>
                                    : ""
                                }
                            </span>
                            <span className="color-val">{colVal.hex}</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <h6 className="text-center">R</h6>
                        <input
                            className="inp-small center mt-2"
                            type="text"
                            readOnly
                            value={colVal.r}
                        />
                    </div>
                    <div className="col-2">
                        <h6 className="text-center">G</h6>
                        <input
                            className="inp-small center mt-2"
                            type="text"
                            readOnly
                            value={colVal.g}
                        />
                    </div>
                    <div className="col-2">
                        <h6 className="text-center">B</h6>
                        <input
                            className="inp-small center mt-2"
                            type="text"
                            readOnly
                            value={colVal.b}
                        />
                    </div>
                </div>
                <div className="color-group">
                    {colorObj.length > 0 ?
                        colorObj.map((curElem, index) => {
                            return (
                                <span key={index} style={{ background: curElem.hex, cursor: "pointer", position: "relative" }} onClick={() => handleChooseColor(curElem, index)}>

                                    {
                                        index === 0 && (props.type === 'background' || props.type === "highlight" || props.type === "highlight background") ?
                                            <div className="remove_line"></div>
                                            : ""
                                    }
                                </span>
                            )
                        })
                        : ""}

                </div>
            </div>
        </div>
    )
}

export default FontColor