import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import { useSelector } from 'react-redux'
import CharacterModal from './CharacterModal'
import { isCharAvailable, isCharAvaliabel, onAddChar, onChangeCharacter, onFetchLipSync } from '../../../../Redux/Actions/VideoActions'
import ColorPalette from './ColorPalette'

const Characters = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const editor = useSelector(state => state.editor)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const [show, setShow] = useState(false);
    const [type, setType] = useState("");

    const [data, setData] = useState([])
    const [charShow, setCharShow] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })

    const handleClose = () => {
        setShow(false)
        setType("")
    };

    const handleShow = (val) => {
        setType(val)
        setShow(true)
    }

    const fetchChar = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-characters", {}, setData, loader, setLoader))
    }

    const handleUse = (data, setUseLoader) => {
        dispatch(onChangeCharacter(data, selectedSlideIndex, selectedLayerIndex))
        setUseLoader(false)
        handleClose()
    }

    const handleLipSync = (e) => {
        const { checked } = e.target;
        let data = {
            id: selectedLayer.characterId
        }
        dispatch(onFetchLipSync(data, checked, selectedLayer, selectedSlideIndex, selectedLayerIndex))
    }

    const handleAddCharacter = () => {
        const charData = data.find(({ id }) => +id === +campaign.characterId)
        if (charData) {
            let arr = data.filter((curElem) => {
                return curElem.category === charData.category
            })
            if (arr.length > 0) {
                dispatch(onAddChar(arr[0], selectedSlideIndex))
            }
        }
    }

    useEffect(() => {
        if (selectedSlide) {
            let val = selectedSlide.layers.find(({ type }) => type === "character") ? true : false
            dispatch(isCharAvailable(val))
        }
    }, [selectedSlide])

    useEffect(() => {
        fetchChar()
    }, [])





    return (
        <div className='list-images'>
            <h2 className='tab-title'>Characters</h2>
            {editor.characterData.isChar ? <div style={{ textAlign: "center", fontSize: "20px", color: "black" }}>Please choose character first</div> :
                <button className="site-link small full mt-2" onClick={handleAddCharacter}><span>Add Character</span></button>}
            {selectedLayer ?
                selectedLayer.type === "character" ?
                    <>
                        <div className="show_character">
                            <div className='col-md-12 ' style={{ height: "100%" }}>
                                <iframe scrolling="no" id='panelIframe' srcDoc={selectedLayer.charString} style={{ pointerEvents: "none", height: "100%", width: "100%", overflow: "hidden" }} />
                            </div>
                        </div>
                        <div className="opt-select mb-3">
                            <h6 className='text-capitalize'>Enable Mouth Animation</h6>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name="lips"
                                    value={selectedLayer.isLipSync}
                                    onChange={(e) => handleLipSync(e)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="show_btn">
                            <div className="col-sm-6 text-center">
                                <button type="button" class="site-link px-3" onClick={() => handleShow("char")}> <span style={{ fontSize: "12px" }}>Change Character</span></button>
                            </div>
                            <div className="col-sm-6 text-center">
                                <button type="button" class="site-link px-3" onClick={() => handleShow("action")}><span style={{ fontSize: "12px" }}>Change Action</span></button>
                            </div>
                        </div>
                        {
                            selectedLayer.charColors ?
                                <div className="row " style={{ justifyContent: "center", width: "100%" }}>
                                    <div className="col-xl-12 col-md-7" >
                                        <div className="character_class text-dark">

                                            {selectedLayer.charColors.hairColor.enable ?
                                                <ColorPalette
                                                    name={"Hair"}
                                                    color={selectedLayer.charColors.hairColor.color}
                                                    className={"character_hairColor"}
                                                    selecteSlideIndex={selectedSlideIndex}
                                                    selectedLayerIndex={selectedLayerIndex}
                                                />
                                                : null
                                            }

                                            {selectedLayer.charColors.skinColor.enable ?
                                                <ColorPalette
                                                    name={"Skin"}
                                                    color={selectedLayer.charColors.skinColor.color}
                                                    className={"character_skinColor"}
                                                    selecteSlideIndex={selectedSlideIndex}
                                                    selectedLayerIndex={selectedLayerIndex}
                                                />
                                                : null
                                            }

                                            {selectedLayer.charColors.topwearColor.enable ?
                                                <ColorPalette
                                                    name={"Topwear"}
                                                    color={selectedLayer.charColors.topwearColor.color}
                                                    className={"character_topwearColor"}
                                                    selecteSlideIndex={selectedSlideIndex}
                                                    selectedLayerIndex={selectedLayerIndex}
                                                />
                                                : null
                                            }

                                            {selectedLayer.charColors.topwear1Color.enable ?
                                                <ColorPalette
                                                    name={"Topwear 1"}
                                                    color={selectedLayer.charColors.topwear1Color.color}
                                                    className={"character_topwear1Color"}
                                                    selecteSlideIndex={selectedSlideIndex}
                                                    selectedLayerIndex={selectedLayerIndex}
                                                />
                                                : null
                                            }

                                            {selectedLayer.charColors.topwear2Color.enable ?
                                                <ColorPalette
                                                    name={"Topwear 2"}
                                                    color={selectedLayer.charColors.topwear2Color.color}
                                                    className={"character_topwear2Color"}
                                                    selecteSlideIndex={selectedSlideIndex}
                                                    selectedLayerIndex={selectedLayerIndex}
                                                />
                                                : null
                                            }
                                            {selectedLayer.charColors.bottomwearColor.enable ?
                                                <ColorPalette
                                                    name={"Bottomwear"}
                                                    color={selectedLayer.charColors.bottomwearColor.color}
                                                    className={"character_bottomwearColor"}
                                                    selecteSlideIndex={selectedSlideIndex}
                                                    selectedLayerIndex={selectedLayerIndex}
                                                />
                                                : null
                                            }
                                            {selectedLayer.charColors.shoesColor.enable ?
                                                <ColorPalette
                                                    name={"Shoes"}
                                                    color={selectedLayer.charColors.shoesColor.color}
                                                    className={"character_ShoesColor"}
                                                    selecteSlideIndex={selectedSlideIndex}
                                                    selectedLayerIndex={selectedLayerIndex}
                                                />
                                                : null
                                            }

                                            {selectedLayer.charColors.accessoriesColor.enable ?
                                                <ColorPalette
                                                    name={"Accessories"}
                                                    color={selectedLayer.charColors.accessoriesColor.color}
                                                    className={"character_accessoriesColor"}
                                                    selecteSlideIndex={selectedSlideIndex}
                                                    selectedLayerIndex={selectedLayerIndex}
                                                />
                                                : null
                                            }

                                        </div>
                                    </div>
                                </div>

                                : null

                        }
                    </> : "" : ""}
            <CharacterModal
                show={show}
                handleClose={handleClose}
                charShow={charShow}
                setCharShow={setCharShow}
                loader={loader}
                data={data}
                type={type}
                selectedLayer={selectedLayer}
                handleUse={handleUse}
                selecteSlideIndex={selectedSlideIndex}
                selectedLayerIndex={selectedLayerIndex}
            />
        </div >
    )
}

export default Characters