import React from 'react'
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const OcpCopyModal = ({ ocp, setOcp }) => {

    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
        let copyText = document.getElementById("copyUrltoCopy");
        setCopied(true)
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }

    useEffect(() => {
        if (!ocp.show) {
            setCopied(false)
        }
    }, [ocp])

    return (
        <Modal className="theme-modal" show={ocp.show} centered>
            <Modal.Body>
                <span className="modal-close" onClick={() => setOcp({
                    ...ocp,
                    show: false,
                    data: ""
                })}><AiOutlineClose /></span>


                <div className="formSec text-center">
                    <div className="inpField">
                        <div className="text-center">
                            <p style={{ color: '#fff', fontSize: "20px", fontWeight: "700", marginBottom: "20px" }}>Your Client Preview URL</p>
                        </div>
                        <input
                            type="text"
                            className="inpx"
                            placeholder="Preview URL"
                            value={ocp.data}
                            id="copyUrltoCopy"
                            readOnly={true}
                        />
                    </div>
                    <button
                        type="button"
                        className="theme-btn mt-2"
                        onClick={() => handleCopy()}
                    >
                        <span>
                            {copied ? "Copied" : "Copy"}
                        </span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OcpCopyModal