import React from 'react';
import Tab from 'react-bootstrap/Tab';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from "query-string"
import TrainingNav from './TrainingNav';

const Training = () => {
    let location = useLocation();
    const articleId = queryString.parse(location.search).id;
    const article = useSelector(state => state.help.article);


    return (
        <>
            <TitleBar title="Add Account" />
            <Header />
            <section className='page-wrap'>
                <div className='container'>

                    <Tab.Container id="left-tabs-example">

                        <div className='upgrade-container'>
                            <div className='upgrade-left'>
                                <TrainingNav
                                    article={article}
                                    articleId={articleId}
                                />
                            </div>
                            <div className='upgrade-right'>
                                <div className='upgrade-right-in'>
                                    <Tab.Content>
                                        {article.length > 0 ?
                                            article.map((curElem, index) => {
                                                return (
                                                    <Tab.Pane  className='fade show active'>
                                                        <React.Fragment key={index}>
                                                            {
                                                                +curElem.id === +articleId ?
                                                                    curElem.items.map((item, ind) => {
                                                                        return (
                                                                            <div className="tabepaneIn-main mt-4" key={ind}>
                                                                                <Link to={`/support-article?id=${articleId}&art_id=${item.id}`}>
                                                                                    <div className="tabepaneIn-cont">
                                                                                        <h3 className="text-dark">
                                                                                            {item.title}
                                                                                        </h3>
                                                                                        <div className="mt-4">
                                                                                            <img src={item.feature_url} className="img-fluid" alt="" />
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : ''}
                                                        </React.Fragment>
                                                    </Tab.Pane>
                                                )
                                            })
                                            : ""}
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </section>

            <Footer />
        </>
    )

}

export default Training;