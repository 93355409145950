import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'

const CustomTopicModal = (props) => {

    const [state, setState] = useState("")

    const handleChange = (e) => {
        setState(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        props.handleUse(state, "", "custom")
    }


    return (
        <Modal className="create-modal theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>
                <form onSubmit={handleSubmit}>

                    <div className="connect-modal">
                        <h3>Enter your custom video topic</h3>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <input
                                        className='common-input'
                                        type="text"
                                        placeholder="Enter video topic"
                                        name='Keyword'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3' style={{ display: "flex", justifyContent: "right" }}>
                        <button type='button' className='site-link bdr' onClick={props.handleClose}><span>Cancel</span></button>
                        <button className='ms-2 site-link' type='submit'><span className='d-flex align-items-center'>{props.loader ? <>Next <i className="fa fa-spinner fa-spin ms-1" /></> : "Next"}</span></button>
                    </div>
                </form>
            </Modal.Body>

        </Modal >
    )
}

export default CustomTopicModal
